import React, { useEffect, useState } from "react";
// ANT DESIGN COMPONENTS
import { Tooltip, Tag } from "antd";
// REDUX
import { useSelector, useDispatch } from "react-redux";
import {
	updateLiftSelected,
	updateLayoutSelected,
	updateModalControlPanelTab,
	updateExistsFaultsInMonitoringView,
	updateExistsFaultsInLiftSelected,
	updatePanelDefaultState,
	updateOpenModalControlPanel,
	updateControlPanelDrawerPlacement,
	updatePanelDefaultStateFaults,
} from "../../../redux/monitoring/monitoringSlice";
// COMPONENTS
import IconDefinitionIcons from "../../tools/iconDefinition/IconDefinitionIcons";
// INTERFACES
import { MonitoringLiftTopViewInterface, SocketMessageInterface } from "../../../interfaces/EdgeManagement.interface";
import { RootState } from "../../../app/store";
// AUTHORIZATION
import validatePermission from "../../../utils/validatePermissions";

function MonitoringLiftTopView({
	lift,
	layout,
	socketMessage,
	iconDefinitionElevator,
}: MonitoringLiftTopViewInterface) {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const dispatch = useDispatch();
	const BLANK_SOCKET_MESSAGE = {
		bank_id: "",
		lift_id: "",
		lift_type: "",
		car_calls: { front: [], rear: [], front_lock: [], rear_lock: [] },
		hall_calls: {
			up: {
				front: [],
				rear: [],
			},
			down: {
				front: [],
				rear: [],
			},
			up_assigned: { front: [], rear: [] },
			down_assigned: { front: [], rear: [] },
		},
		faults: false,
		position: 0,
		icon: {
			type: "",
			name: "",
			description: "",
		},
		service_mode: {
			name: "",
			description: "",
		},
		heartbeat: 0,
	};

	// ************************************************ */
	// USE STATE VARIABLES **************************** */
	const [lastShortMesage, setLastShortMessage] = useState<string>("elOutIndicator");
	const [hasFaults, setHasFaults] = useState<boolean>(false);
	const [faultMessage, setFaultMessage] = useState<boolean>(false);
	const [lastSocketMessage, setLastSocketMessage] = useState<SocketMessageInterface>(BLANK_SOCKET_MESSAGE);

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { theme } = useSelector((state: RootState) => state.home);
	const { elevatorIconDefinitions, faults } = useSelector((state: RootState) => state.monitoring);
	const { permissions } = useSelector((state: any) => state.user);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const getTabKey = () => {
		const key = "CARCALLS";
		if (hasFaults || faultMessage) {
			return "FAULTS";
		}
		if (validatePermission("monitor-control-panel-car-calls", permissions)) {
			return "CARCALLS";
		}
		if (validatePermission("monitor-control-panel-hall-calls", permissions)) {
			return "HALLCALLS";
		}
		if (validatePermission("monitor-control-panel-actions", permissions)) {
			return "ACTIONS";
		}
		if (validatePermission("monitor-control-panel-faults", permissions)) {
			return "FAULTS";
		}
		if (validatePermission("monitor-control-panel-status", permissions)) {
			return "STATUS";
		}
		return key;
	};

	const getDrawerPlacement = (e: any) => {
		const clickX = e.clientX;
		const screenWidth = window.innerWidth;
		if (clickX > screenWidth / 2) {
			dispatch(updateControlPanelDrawerPlacement("left"));
		} else {
			dispatch(updateControlPanelDrawerPlacement("right"));
		}
	};

	const onClickOpenModalElevator = (e: any) => {
		getDrawerPlacement(e);
		dispatch(updateLiftSelected(lift));
		dispatch(updateLayoutSelected(layout));
		dispatch(
			updateModalControlPanelTab({
				liftType: "ELEVATOR",
				tab: getTabKey(),
			})
		);
		dispatch(updateOpenModalControlPanel(true));
		dispatch(updateExistsFaultsInLiftSelected(hasFaults));
		dispatch(updatePanelDefaultState(lastSocketMessage));
		dispatch(updatePanelDefaultStateFaults(hasFaults));
	};

	// ************************************************* */
	// USE EFFECT ************************************** */
	useEffect(() => {
		if (lift && lift.id && lift.id === socketMessage.lift_id) {
			setLastSocketMessage(socketMessage);
		}
	}, [socketMessage]);

	useEffect(() => {
		if (elevatorIconDefinitions.includes(iconDefinitionElevator[lastSocketMessage.icon.name.toLowerCase()])) {
			setLastShortMessage(iconDefinitionElevator[lastSocketMessage.icon.name.toLowerCase()]);
		}
		// FAULT MESSAGE
		if (lastSocketMessage.icon.type.toUpperCase() === "FAULT") {
			setFaultMessage(true);
			setHasFaults(true);
			dispatch(updateExistsFaultsInMonitoringView(true));
			setTimeout(() => {
				setFaultMessage(false);
			}, 10000);
		}
	}, [lastSocketMessage]);

	useEffect(() => {
		if (
			faults &&
			faults.liftId &&
			faults.liftId !== "" &&
			lift &&
			faults.type === "LIFT" &&
			faults.liftId === lift.id
		) {
			setHasFaults(false);
			setFaultMessage(false);
		}
		if (faults && faults.type === "ALL") {
			setHasFaults(false);
			setFaultMessage(false);
		}
	}, [faults]);
	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div>
			<div>
				<div
					style={{
						height: "50px",
						width: "50px",
						display: "flex",
						flexDirection: "column",
						marginLeft: "5px",
						marginRight: "5px",
					}}
					className={`monitoringView__topViewContainer__${theme}  ${
						faultMessage ? `monitoringView__dangerElevator   ` : ""
					}${faultMessage ? `monitoringView__dangerElevator__top ` : ""}${
						faultMessage ? `monitoringView__dangerElevator__bottom` : ""
					}  `}
				>
					<div
						className={`monitoringView__topViewContainer__${theme}`}
						style={{
							width: "100%",
							height: "100%",
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
						}}
					>
						<IconDefinitionIcons
							isMap={false}
							expanded={false}
							liftType='ELEVATOR'
							icon={lastShortMesage}
							iconSize={20}
						/>
					</div>
				</div>
				{/** ELEVATOR NAME */}
				<div
					role='button'
					tabIndex={0}
					onKeyDown={() => {}}
					onClick={onClickOpenModalElevator}
					style={{
						fontSize: "9px",
						fontWeight: "bolder",
						textAlign: "center",
						marginTop: "5px",
					}}
				>
					{faultMessage || hasFaults ? (
						<div>
							<div>
								<Tooltip placement='top' title={lift.name}>
									<span className='generalStyles__error'>
										{lift && lift.name ? lift.name.toUpperCase().substring(0, 6) : ""}
									</span>
								</Tooltip>
							</div>
							<div>
								<div>
									<Tag
										style={{
											marginLeft: "5px",
											marginTop: "2px",
											fontSize: "9px",
										}}
										color='red'
									>
										FAULT
									</Tag>
								</div>
								{lastSocketMessage.service_mode.name !== "" && (
									<div>
										<Tooltip placement='top' title={lastSocketMessage.service_mode.description}>
											<Tag style={{ marginLeft: "3px", marginTop: "5px" }} color='cyan'>
												{lastSocketMessage.service_mode.name.toUpperCase().substring(0, 3)}
											</Tag>
										</Tooltip>
									</div>
								)}
							</div>
						</div>
					) : (
						<div>
							<div>
								<Tooltip placement='top' title={lift.name}>
									<span>{lift && lift.name ? lift.name.toUpperCase().substring(0, 6) : ""}</span>
								</Tooltip>
							</div>
							{lastSocketMessage.service_mode.name !== "" && (
								<div>
									<Tooltip placement='top' title={lastSocketMessage.service_mode.description}>
										<Tag style={{ marginLeft: "6px", marginTop: "5px" }} color='cyan'>
											{lastSocketMessage.service_mode.name.toUpperCase().substring(0, 3)}
										</Tag>
									</Tooltip>
								</div>
							)}
						</div>
					)}
				</div>
			</div>
		</div>
	);
}

export default MonitoringLiftTopView;
