import React, { useEffect, useState } from "react";
import { Tooltip } from "antd";
// FONT AWESOME LIBRARY AND ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretRight } from "@fortawesome/free-solid-svg-icons";
// REDUX
import { useSelector } from "react-redux";
// COMPONENTES
import IconDefinitionIcons from "../../tools/iconDefinition/IconDefinitionIcons";
// INTERFACES
import { MapViewPin3Interface } from "../../../interfaces/MapView.interface";
import { RootState } from "../../../app/store";
import MonitoringIconsEscalators from "../monitoringIcons/MonitoringIconsEscalators";
import MonitoringIconsMovingWalks from "../monitoringIcons/MonitoringIconsMovingWalks";

function MonitoringMapViewPin3({
  carName,
  firstStop,
  secondStop,
  icon,
  serviceMode,
  direction,
  orientation,
  fault,
  liftType,
  lift,
}: MapViewPin3Interface) {
  // ************************************************ */
  // GLOBAL VARIABLES ******************************* */
  const [showContent, setShowContent] = useState<boolean>(false);
  const [boxSize, setBoxSize] = useState<"sm" | "md" | "lg">("sm");
  const [canShowServiceMode, setCanShowServiceMode] = useState<boolean>(false);
  const [canShowName, setCanShowName] = useState<boolean>(false);
  const [canShowFloors, setCanShowFloors] = useState<boolean>(false);

  // ************************************************ */
  // USE STATE VARIABLES **************************** */

  // ************************************************ */
  // REDUX SLICE VARIABLES ************************** */
  const { theme } = useSelector((state: RootState) => state.home);
  const { serviceModeEnabled, floorsEnabled, namesEnabled } = useSelector(
    (state: any) => state.mapView
  );

  // ************************************************ */
  // SERVICES AND API CALLS ************************* */

  // ************************************************ */
  // FUNCTIONS ************************************** */
  const handleMouseOver = () => {
    setShowContent(true);
  };
  const handleMouseLeave = () => {
    setShowContent(false);
  };
  const getPinClass = () => {
    let pinClass = "mapViewStyles__box";
    pinClass = pinClass.concat(" mapViewStyles__box__", theme);
    if (showContent || canShowServiceMode || canShowFloors || canShowName) {
      pinClass = pinClass.concat(
        " mapViewStyles__box__",
        boxSize,
        "__",
        orientation,
        "__",
        "expanded"
      );
    } else {
      pinClass = pinClass.concat(" mapViewStyles__box__minimized");
    }
    pinClass = pinClass.concat(
      " mapViewStyles__arrow__",
      direction,
      "__",
      theme
    );
    pinClass = pinClass.concat(
      " mapViewStyles__box__",
      boxSize,
      "__",
      orientation
    );
    if (fault) {
      pinClass = pinClass.concat(
        " mapViewStyles__box__error__",
        direction,
        "__",
        theme
      );
    }
    return pinClass;
  };

  const getAlignItemClass = () => {
    if (direction === "top") {
      return "flex-start";
    }
    if (direction === "bottom") {
      return "flex-end";
    }
    return "center";
  };

  const getJustifyContentClass = () => {
    if (direction === "top") {
      return "center";
    }
    if (direction === "bottom") {
      return "center";
    }
    if (direction === "right") {
      return "flex-end";
    }
    if (direction === "left") {
      return "flex-start";
    }
    return "center";
  };

  const getAlignItems = () => {
    if (direction === "top") {
      return "flex-start";
    }
    if (direction === "bottom") {
      return "flex-end";
    }
    return "center";
  };

  const getJustifyItems = () => {
    if (direction === "left") {
      return "flex-start";
    }
    if (direction === "right") {
      return "flex-end";
    }
    return "center";
  };

  const adjustSize = () => {
    if (canShowServiceMode && canShowName && canShowFloors) {
      setBoxSize("lg");
    } else if (
      (canShowServiceMode && canShowName) ||
      (canShowServiceMode && canShowFloors) ||
      (canShowName && canShowFloors)
    ) {
      setBoxSize("md");
    } else {
      setBoxSize("sm");
    }
  };
  // ************************************************ */
  // USE EFFECT ************************************* */
  useEffect(() => {
    if (
      (serviceModeEnabled || showContent) &&
      serviceMode &&
      serviceMode !== ""
    )
      setCanShowServiceMode(true);
    else setCanShowServiceMode(false);

    if ((namesEnabled || showContent) && carName && carName !== "")
      setCanShowName(true);
    else setCanShowName(false);

    if ((floorsEnabled || showContent) && firstStop && firstStop !== "")
      setCanShowFloors(true);
    else setCanShowFloors(false);

    adjustSize();
  }, [
    serviceModeEnabled,
    showContent,
    serviceMode,
    namesEnabled,
    carName,
    floorsEnabled,
    firstStop,
  ]);

  useEffect(() => {
    adjustSize();
  }, [canShowServiceMode, canShowName, canShowFloors]);

  // ************************************************ */
  // COMPONENT ************************************** */
  return (
    <div
      style={{
        width: orientation === "vertical" ? 40 : 72,
        height: orientation === "vertical" ? 72 : 40,
        display: "flex",
        alignItems: orientation === "vertical" ? getAlignItems() : "",
        justifyContent: orientation !== "vertical" ? getJustifyItems() : "",
      }}
    >
      {/** ************************************************************** */}
      {/** VERTICAL */}
      {orientation === "vertical" && (
        <div
          className={`mapViewStyles__box__${boxSize}__vertical__expanded`}
          style={{
            justifyContent: getJustifyContentClass(),
            alignItems: getAlignItemClass(),
            display: "flex",
            borderRadius: "8px",
          }}
        >
          <div
            className={getPinClass()}
            onMouseOver={handleMouseOver}
            onMouseLeave={handleMouseLeave}
            onFocus={() => {}}
          >
            {/** CAR NAME */}
            {canShowName && (
              <div
                style={{
                  height: "16px",
                  fontSize: "10px",
                  width: "90%",
                  marginLeft: "5%",
                  marginRight: "5%",
                  paddingTop: "3px",
                }}
                className="generalStyles__textEllipsis"
              >
                {carName.length >= 8 ? (
                  <Tooltip placement="top" title={carName}>
                    <p>{carName}</p>
                  </Tooltip>
                ) : (
                  <p>{carName}</p>
                )}
              </div>
            )}
            {/** ICON */}
            {icon && (
              <div
                style={{
                  width: "100%",
                }}
              >
                {["elevator", "map"].includes(liftType) && (
                  <IconDefinitionIcons
                    isMap
                    expanded={showContent}
                    liftType={liftType?.toUpperCase()}
                    icon={icon} // last short message
                    iconSize={16}
                  />
                )}
                {liftType === "escalator" && (
                  <MonitoringIconsEscalators
                    lift={lift}
                    shortMessage={icon}
                    iconSize={28}
                    mapViewClass={
                      showContent || namesEnabled
                        ? "mapViewStyles__icon__escalator__expanded"
                        : "mapViewStyles__icon__escalator__minimized"
                    }
                  />
                )}
                {liftType === "movingwalk" && (
                  <MonitoringIconsMovingWalks
                    lift={lift}
                    shortMessage={icon}
                    iconSize={28}
                    mapViewClass={
                      showContent || namesEnabled
                        ? "mapViewStyles__icon__escalator__expanded"
                        : "mapViewStyles__icon__escalator__minimized"
                    }
                  />
                )}
              </div>
            )}
            {/** NEXT STOP */}
            {canShowFloors && (
              <Tooltip
                placement="bottom"
                title={`${firstStop} > ${secondStop}`}
              >
                <div
                  className="generalStyles__flex"
                  style={{
                    fontSize: "10px",
                    width: "100%",
                    height: "16px",
                  }}
                >
                  <div
                    className=""
                    style={{ width: "33%", textAlign: "center" }}
                  >
                    <p>{firstStop.substring(0, 2)}</p>{" "}
                  </div>
                  <div
                    className=""
                    style={{ width: "33%", textAlign: "center" }}
                  >
                    <FontAwesomeIcon icon={faCaretRight} />
                  </div>
                  <div
                    className=""
                    style={{ width: "33%", textAlign: "center" }}
                  >
                    <p>{secondStop.substring(0, 2)}</p>
                  </div>
                </div>
              </Tooltip>
            )}
            {/** SERVICE MODE */}
            {canShowServiceMode && (
              <>
                {serviceMode.length >= 3 && (
                  <Tooltip placement="bottom" title={`${serviceMode}`}>
                    <div
                      style={{
                        height: "16px",
                        width: "100%",
                        fontSize: "10px",
                        color: "cyan",
                      }}
                    >
                      {serviceMode.toUpperCase().substring(0, 3)}
                    </div>
                  </Tooltip>
                )}
                {serviceMode.length < 3 && (
                  <div
                    style={{
                      height: "16px",
                      width: "100%",
                      fontSize: "10px",
                      color: "cyan",
                    }}
                  >
                    {serviceMode.toUpperCase()}
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      )}
      {/** ************************************************************** */}
      {/** HORIZONTAL */}
      {orientation === "horizontal" && (
        <div
          className={`mapViewStyles__box__${boxSize}__horizontal__expanded`}
          style={{
            justifyContent: getJustifyContentClass(),
            alignItems: getAlignItemClass(),
            display: "flex",
            borderRadius: "8px",
          }}
        >
          {" "}
          <div
            className={getPinClass()}
            onMouseOver={handleMouseOver}
            onMouseLeave={handleMouseLeave}
            onFocus={() => {}}
          >
            {/** CAR NAME */}
            {canShowName && (
              <div
                style={{
                  height: "16px",
                  fontSize: "10px",
                  width: "90%",
                  marginLeft: "5%",
                  marginRight: "5%",
                  paddingTop: "3px",
                }}
                className="generalStyles__textEllipsis"
              >
                {carName.length >= 8 ? (
                  <Tooltip placement="top" title={carName}>
                    <p>{carName}</p>
                  </Tooltip>
                ) : (
                  <p>{carName || "--"}</p>
                )}
              </div>
            )}
            <div className="generalStyles__flex">
              {/** NEXT STOP */}
              {canShowFloors && (
                <Tooltip
                  placement="bottom"
                  title={`${firstStop} > ${secondStop}`}
                >
                  <div
                    className="generalStyles__flex"
                    style={{
                      fontSize: "10px",
                      width: "40px",
                      height: "32px",
                      paddingTop: "4px",
                    }}
                  >
                    <div
                      className=""
                      style={{ width: "33%", textAlign: "center" }}
                    >
                      <p>{firstStop.substring(0, 2)}</p>{" "}
                    </div>
                    <div
                      className=""
                      style={{ width: "33%", textAlign: "center" }}
                    >
                      <FontAwesomeIcon icon={faCaretRight} />
                    </div>
                    <div
                      className=""
                      style={{ width: "33%", textAlign: "center" }}
                    >
                      <p>{secondStop.substring(0, 2)}</p>
                    </div>
                  </div>
                </Tooltip>
              )}
              {/** ICON */}
              {icon && (
                <div
                  style={{
                    width: "40px",
                  }}
                >
                  {["elevator", "map"].includes(liftType) && (
                    <IconDefinitionIcons
                      isMap
                      expanded={showContent}
                      liftType={liftType?.toUpperCase()}
                      icon={icon} // last short message
                      iconSize={16}
                    />
                  )}
                  {liftType === "escalator" && (
                    <MonitoringIconsEscalators
                      lift={lift}
                      shortMessage={icon}
                      iconSize={28}
                      mapViewClass={
                        showContent || namesEnabled
                          ? "mapViewStyles__icon__escalator__expanded"
                          : "mapViewStyles__icon__escalator__minimized"
                      }
                    />
                  )}
                  {liftType === "movingwalk" && (
                    <MonitoringIconsMovingWalks
                      lift={lift}
                      shortMessage={icon}
                      iconSize={28}
                      mapViewClass={
                        showContent || namesEnabled
                          ? "mapViewStyles__icon__escalator__expanded"
                          : "mapViewStyles__icon__escalator__minimized"
                      }
                    />
                  )}
                </div>
              )}
              {/** SERVICE MODE */}
              {canShowServiceMode && (
                <>
                  {serviceMode.length >= 3 && (
                    <Tooltip placement="bottom" title={`${serviceMode}`}>
                      <div
                        style={{
                          height: "16px",
                          width: "100%",
                          fontSize: "10px",
                          color: "cyan",
                        }}
                      >
                        {serviceMode.toUpperCase().substring(0, 3)}
                      </div>
                    </Tooltip>
                  )}
                  {serviceMode.length < 3 && (
                    <div
                      style={{
                        height: "16px",
                        width: "100%",
                        fontSize: "10px",
                        color: "cyan",
                      }}
                    >
                      {serviceMode.toUpperCase()}
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
export default MonitoringMapViewPin3;
