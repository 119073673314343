/* eslint-disable no-nested-ternary */
import {
  Route,
  Routes,
  Link,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { Auth } from "aws-amplify";
import React, { useEffect, useState } from "react";
// DEVICE DETECT
import { isMobile } from "react-device-detect";
// ANT DESIGN COMPONENTS
import { Layout, Menu, Spin } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// FONT AWESOME LIBRARY AND ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBuilding,
  faBuildingUser,
  faUsers,
  faUserShield,
  faShieldHalved,
  faTools,
  faGears,
  faServer,
  faBuildingShield,
  faElevator,
  faFileCircleCheck,
  faChartGantt,
  faPlugCircleCheck,
  faEthernet,
  faObjectGroup,
  faTriangleExclamation,
  faChartPie,
  faTable,
  faUpDown,
  faPieChart,
  faGlobe,
  faEnvelopeCircleCheck,
  faMap,
} from "@fortawesome/free-solid-svg-icons";
// SWITCH THEME
import { useThemeSwitcher } from "react-css-theme-switcher";
// REDUX
import { useSelector, useDispatch } from "react-redux";
import {
  updateBreadcrumbTitle,
  updateUserLogged,
  updateTheme,
  updateSideBarCollapsed,
} from "../../redux/home/homeSlice";
import { updatePermissions } from "../../redux/users/userSlice";
import { useLazyGetCurrentLoggedUserQuery } from "../../redux/users/userAPI";
import { updateServer } from "../../redux/servers/serverSlice";
import { updateRenderComponent } from "../../redux/channels/channelSlice";
// COMPONENTS
import LayoutBreadcrumb from "./LayoutBreadcrumb";
import UsersPage from "../userManagement/users/UsersPage";
import OrganizationsPage from "../organizationManagement/organizations/OrganizationsPage";
import RolesPage from "../organizationManagement/roles/RolesPage";
import RootPermissionsPage from "../tools/rootPermissions/RootPermissionsPage";
import OffsetTypePage from "../tools/offsetType/OffsetTypePage";
import YourProfileDrawer from "./YourProfileDrawer";
import GlobalAlert from "./GlobalAlert";
import MonitoringPage from "../monitoringManagement/monitoring/MonitoringPage";
import NotFoundPage from "../unauthorized/NotFoundPage";
import BanksPage from "../edgeManagement/banks/BanksPage";
import ServersPage from "../edgeManagement/servers/ServersPage";
import LiftsPage from "../edgeManagement/lifts/LiftsPage";
import ConfigurationFiles from "../configurationManagement/configurationFiles/ConfigurationFilesPage";
import DefinitionsPage from "../configurationManagement/definitions/DefinitionsPage";
import OffsetsPage from "../configurationManagement/offsets/OffsetsPage";
import SpecialSwitchesPage from "../tools/specialSwitches/SpecialSwitchesPage";
import SpecialSwitchesCatalogPage from "../tools/specialSwitchesCatalog/SpecialSwitchesCatalogPage";
import DataBlocksPage from "../tools/dataBlocks/DataBlocksPage";
import IconDefinitionPage from "../tools/iconDefinition/IconDefinitionPage";
import PermissionsPage from "../tools/permissions/PermissionsPage";
import ReportGeneralPage from "../reports/generalReports/ReportGeneralPage";
import ReportGeneralCurrentOutOfService from "../reports/generalReports/ReportGeneralCurrentOutOfService";
import ReportGeneralBeneficialUsage from "../reports/generalReports/ReportGeneralBeneficialUsage";
import ReportGeneralEscalatorInUse from "../reports/generalReports/ReportGeneralEscalatorInUse";
import ReportGeneralPerformance from "../reports/generalReports/ReportGeneralPerformance";
import ReportGeneralFireService from "../reports/generalReports/ReportGeneralFireService";
import ReportFaultGeneral from "../reports/fault/ReportFaultGeneral";
import ReportFaultLog from "../reports/fault/ReportFaultLog";
import ReportFaultRecent from "../reports/fault/ReportFaultRecent";
import ReportFaultChartDay from "../reports/fault/ReportFaultChartDay";
import ReportFaultChartLift from "../reports/fault/ReportFaultChartLift";
import ReportFaultChartFloor from "../reports/fault/ReportFaultChartFloor";
import ReportFaultChartFault from "../reports/fault/ReportFaultChartFault";
import ReportTrafficChartHallButtonCountsByFloor from "../reports/traffic/ReportTrafficChartHallButtonCountsByFloor";
import ReportTrafficChartHallButtonCountsByTimeOfDay from "../reports/traffic/ReportTrafficChartHallButtonCountsByTimeOfDay";
import ReportTrafficChartTripsToAndFromAFloor from "../reports/traffic/ReportTrafficChartTripsToAndFromAFloor";
import ReportTrafficDetailedSummary from "../reports/traffic/ReportTrafficDetailedSummary";
import ReportTrafficHallCallSummary from "../reports/traffic/ReportTrafficHallCallSummary";
import ReportTrafficChartAverageByFloor from "../reports/traffic/ReportTrafficChartAverageByFloor";
import ReportTrafficChartAverageByTimeOfDay from "../reports/traffic/ReportTrafficChartAverageByTimeOfDay";
import ReportTrafficChartDistributionByHourlyInterval from "../reports/traffic/ReportTrafficChartDistributionByHourlyInterval";
import ReportTrafficChartDistributionDetailedTotal from "../reports/traffic/ReportTrafficChartDistributionDetailedTotal";
import ReportTrafficChartDistributionDetailedDirection from "../reports/traffic/ReportTrafficChartDistributionDetailedDirection";
import ReportTrafficLongestWaitTimeList from "../reports/traffic/ReportTrafficLongestWaitTimeList";
import ReportPerformancePage from "../reports/performance/ReportPerformancePage";
import ReportOutOfServicePage from "../reports/outOfService/ReportOutOfServicePage";
import ReportTrafficGeneral from "../reports/traffic/ReportTrafficGeneral";
import ReportCarUsagePage from "../reports/carUsage/ReportCarUsagePage";
import ReportCarUseChartByHour from "../reports/carUsage/ReportCarUseChartByHour";
import ReportCarUseChartComparisons from "../reports/carUsage/ReportCarUseChartComparisons";
import ReportCarUseChartTimes from "../reports/carUsage/ReportCarUseChartTimes";
import ReportCarServiceGeneral from "../reports/carService/ReportCarServiceGeneral";
import ReportCarServiceChartServiceLog from "../reports/carService/ReportCarServiceChartServiceLog";
import ReportCarServiceServiceLog from "../reports/carService/ReportCarServiceServiceLog";
import ReportCarServiceLog from "../reports/carService/ReportCarServiceLog";
import ReportCarServiceStatistics from "../reports/carService/ReportCarServiceStatistics";
import ReportFaultFrequencyCar from "../reports/fault/ReportFaultFrequencyCar";
import ReportFaultFrequencyFault from "../reports/fault/ReportFaultFrequencyFault";
import ReportBeneficialPage from "../reports/beneficial/ReportBeneficialPage";
import VendorsPage from "../tools/vendors/VendorsPage";
import AdaptersPage from "../edgeManagement/adapters/AdaptersPage";
import ActionsPage from "../tools/actions/ActionsPage";
import ChannelsPage from "../edgeManagement/channels/ChannelsPage";
import TermsAndConditions from "./TermsAndConditions";
import MessageTypePage from "../tools/messageType/MessageTypePage";
import MessagesPage from "../tools/messages/MessagesPage";
import GroupsPage from "../edgeManagement/groups/GroupsPage";
import NotificationBanksPage from "../notificationManagement/banks/NotificationBanksPage";
import NotificationsPage from "../notificationManagement/notifications/NotificationsPage";
import NotificationUsersPage from "../notificationManagement/users/NotificationUsersPage";
import NotificationSchedulesPage from "../notificationManagement/schedules/NotificationSchedulesPage";
import MapViewPage from "../edgeManagement/map-view/MapViewPage";
import MapViewConfigurationPage from "../edgeManagement/map-view/MapViewConfigurationPage";
// AUTHORIZATION
import GETJwtToken from "../../redux/authentication/authentication";
import validatePermission from "../../utils/validatePermissions";
// STYLES
import "../../styles/generalStyles.scss";
import logoBlack from "../../assets/logo/black-bg-lnlogo.png";
import logoWhite from "../../assets/logo/white-bg-lnlogo.png";
import mobileLogo from "../../assets/logo/lnLogo.png";
import UserNotFound from "./UserNotFound";

const { Header, Content, Footer, Sider } = Layout;

const App = () => {
  // ************************************************ */
  // LOCAL STORAGE AND VARIABLES ******************** */
  const { switcher, themes } = useThemeSwitcher();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const [t] = useTranslation("global");
  const { theme, userLogged, sideBarCollapsed } = useSelector(
    (state: any) => state.home
  );
  const { permissions } = useSelector((state: any) => state.user);
  const [current, setCurrent] = useState<any>("");
  const [openKeys, setOpenKeys] = useState([""]);
  const [isLoading, setIsLoading] = useState(false);
  const [localShowFullView, setLocalShowFullView] = useState(false);
  const rootSubmenuKeys = [
    "users",
    "monitoring",
    "map-view/map",
    "roles",
    "organizations",
    "edge-management/servers",
    "edge-management/banks",
    "edge-management/lifts",
    "edge-management/adapters",
    "edge-management/channels",
    "edge-management/groups",
    "edge-management/map-view",
    "root-permissions",
    "offset-type-configuration",
    "configuration-catalog",
    "configuration-catalog/definitions",
    "configuration-catalog/offsets",
    "reports/report-general",
    "reports/general/report-current-out-of-service",
    "reports/general/report-performance",
    "reports/general/report-beneficial-usage",
    "reports/general/report-escalator-in-use",
    "reports/general/report-fire-service",
    "reports/report-fault",
    "reports/faults/report-general",
    "reports/faults/report-log",
    "reports/faults/report-recent",
    "reports/faults/chart-day",
    "reports/faults/chart-fault",
    "reports/faults/chart-floor",
    "reports/faults/chart-lift",
    "reports/faults/report-car",
    "reports/faults/report-fault",
    "reports/report-traffic",
    "reports/report-car-user",
    "reports/car-use/chart-by-hour",
    "reports/car-use/chart-comparisons",
    "reports/car-use/chart-times",
    "reports/report-service",
    "reports/car-service/chart-service-log",
    "reports/car-service/report-log",
    "reports/car-service/report-statistics",
    "reports/report-beneficial",
    "reports/report-performance",
    "reports/report-out-of-service",
    "tools/data-blocks",
    "tools/root-permissions",
    "tools/offset-type-configuration",
    "tools/special-switches-configuration",
    "tools/special-switches-catalog-configuration",
    "tools/vendors",
    "tools/icon-definition",
    "tools/permissions",
    "tools/actions",
    "tools/message-type",
    "tools/messages",
    "notification-management/notifications-banks",
    "notification-management/notifications",
    "notification-management/notifications-users",
    "notification-management/notifications-schedules",
  ];
  const { showFullView } = useSelector((state: any) => state.monitoring);

  // ************************************************ */
  // SERVICES AND API CALLS ************************* */
  const [
    triggerGetCurrentLoggedUserQuery,
    {
      isLoading: getCurrentLoggedUserIsLoading,
      isError: getCurrentLoggedUserIsError,
    },
  ] = useLazyGetCurrentLoggedUserQuery();
  // ************************************************* */
  // FUNCTIONS *************************************** */
  const showIsLoading = () => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 300);
  };

  const handleChangeHeaderTitle = (title: string) => {
    localStorage.setItem("headerTitle", title);
    dispatch(updateBreadcrumbTitle(title));
  };

  const getCurrentLoggedUser = async () => {
    // Online authentication
    const token = await GETJwtToken();
    const response = await triggerGetCurrentLoggedUserQuery({
      token,
    }).unwrap();

    if (response && response.data && response.data.user) {
      dispatch(updateUserLogged(response.data.user));
      if (
        response.data.user.pref_theme &&
        response.data.user.pref_theme !== ""
      ) {
        switcher({ theme: themes[response.data.user.pref_theme] });
        localStorage.setItem("theme", response.data.user.pref_theme);
        dispatch(updateTheme(response.data.user.pref_theme));
      }
      if (response.data.user.role && response.data.user.role.menu) {
        dispatch(updatePermissions(response.data.user.role.menu));
      }
    }
  };

  const onChangeTab: any = (e: any) => {
    if (e.key) {
      setCurrent(e.key.toString());
    }
  };
  const onOpenChange = (keys: any) => {
    const latestOpenKey = keys.find((key: any) => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  const updateSideBarTab = (current_: string, key_: string) => {
    handleChangeHeaderTitle(current_);
    setCurrent(current_);
    setOpenKeys([key_]);
  };

  const updateSideBarTabCascader = (current_: string, keys_: string[]) => {
    handleChangeHeaderTitle(current_);
    setCurrent(current_);
    setOpenKeys(keys_);
  };

  // eslint-disable-next-line no-unused-vars
  const signOut = async () => {
    await Auth.signOut();
    window.location.href = process.env.REACT_APP_LOGIN_ROUTE || "";
  };

  const onClickLiftnetLogo = () => {
    navigate({
      pathname: "/monitoring",
    });
  };

  // ************************************************* */
  // USE EFFECT ************************************** */
  useEffect(() => {
    getCurrentLoggedUser();
    const headerTitle = localStorage.getItem("headerTitle");
    setCurrent(headerTitle);
    if (
      headerTitle === "roles" ||
      headerTitle === "organizations" ||
      headerTitle === "users"
    ) {
      setOpenKeys(["organizationManagement"]);
    } else if (
      headerTitle === "servers" ||
      headerTitle === "banks" ||
      headerTitle === "lifts" ||
      headerTitle === "adapters" ||
      headerTitle === "configurationCatalog" ||
      headerTitle === "channels" ||
      headerTitle === "groups"
    ) {
      setOpenKeys(["edgeManagement"]);
    } else if (
      headerTitle === "notificationsBanks" ||
      headerTitle === "notifications" ||
      headerTitle === "notificationsUsers" ||
      headerTitle === "notificationsSchedules"
    ) {
      setOpenKeys(["notifications"]);
    } else if (
      headerTitle === "rootPermissions" ||
      headerTitle === "offsetTypeConfiguration" ||
      headerTitle === "specialSwitches" ||
      headerTitle === "specialSwitchesCatalog" ||
      headerTitle === "dataBlocks" ||
      headerTitle === "vendors" ||
      headerTitle === "icon-definition" ||
      headerTitle === "permissions" ||
      headerTitle === "actions" ||
      headerTitle === "messageType" ||
      headerTitle === "message"
    ) {
      setOpenKeys(["tools"]);
    } else if (
      headerTitle === "reportGeneral" ||
      headerTitle === "reportFault" ||
      headerTitle === "reportTraffic" ||
      headerTitle === "reportCarUsage" ||
      headerTitle === "reportService" ||
      headerTitle === "reportBeneficial" ||
      headerTitle === "reportPerformance" ||
      headerTitle === "reportOutOfService"
    ) {
      setOpenKeys(["reports"]);
    } else if (
      headerTitle === "reportFaultsReportGeneral" ||
      headerTitle === "reportFaultsReportLog" ||
      headerTitle === "reportFaultsReportRecent" ||
      headerTitle === "reportFaultsChartDay" ||
      headerTitle === "reportFaultsChartFault" ||
      headerTitle === "reportFaultsChartFloor" ||
      headerTitle === "reportFaultsChartLift" ||
      headerTitle === "reportFaultsFrequencyReportCar" ||
      headerTitle === "reportFaultsFrequencyReportFault"
    ) {
      setOpenKeys(["reports", "faults"]);
    } else if (
      headerTitle === "reportTrafficChartHallButtonCountsByFloor" ||
      headerTitle === "reportTrafficChartHallButtonCountsByTimeOfDay" ||
      headerTitle === "reportTrafficChartTripsToAndFromAFloor" ||
      headerTitle === "reportTrafficReportDetailedSummary" ||
      headerTitle === "reportTrafficReportHallCallSummaryReport" ||
      headerTitle === "reportTrafficChartAverageByFloor" ||
      headerTitle === "reportTrafficChartAverageByTimeOfDay" ||
      headerTitle === "reportTrafficChartDistributionByHourlyInterval" ||
      headerTitle === "reportTrafficChartDistributionDetailedTotal" ||
      headerTitle === "reportTrafficChartDistributionDetailedDirection" ||
      headerTitle === "reportTrafficReportLongestWaitTimeList"
    ) {
      setOpenKeys(["reports", "carUse"]);
    } else if (
      headerTitle === "reportCarUseChartByHour" ||
      headerTitle === "reportCarUseChartComparisons" ||
      headerTitle === "reportCarUseChartTimes"
    ) {
      setOpenKeys(["reports", "carUse"]);
    } else if (
      headerTitle === "reportCarServiceChartServiceLog" ||
      headerTitle === "reportCarServiceServiceLog" ||
      headerTitle === "reportCarServiceReportLog" ||
      headerTitle === "reportCarServiceReportStatistics"
    ) {
      setOpenKeys(["reports", "carService"]);
    } else if (
      headerTitle === "reportGeneralReportCurrentOutOfservice" ||
      headerTitle === "reportGeneralReportPerformance" ||
      headerTitle === "reportGeneralReportBeneficialUsage" ||
      headerTitle === "reportGeneralReportEscalatorInUse" ||
      headerTitle === "reportGeneralReportFireService"
    ) {
      setOpenKeys(["reports", "general"]);
    }
  }, []);

  useEffect(() => {
    if (localStorage.getItem("headerTitle") === "monitoring") {
      setCurrent("monitoring");
    }
  }, [localStorage.getItem("headerTitle")]);

  useEffect(() => {
    if (location && location.pathname) {
      switch (location.pathname) {
        case "/monitoring":
          updateSideBarTab("monitoring", "monitoring");
          break;
        case "/map-view/map":
          updateSideBarTab("map", "mapView");
          break;
        case "/organization-management/roles":
          updateSideBarTab("roles", "organizationManagement");
          break;
        case "/organization-management/organizations":
          updateSideBarTab("organizations", "organizationManagement");
          break;
        case "/organization-management/users":
          updateSideBarTab("users", "organizationManagement");
          break;
        case "/edge-management/servers":
          updateSideBarTab("servers", "edgeManagement");
          break;
        case "/edge-management/adapters":
          updateSideBarTab("adapters", "edgeManagement");
          break;
        case "/edge-management/channels":
          updateSideBarTab("channels", "edgeManagement");
          break;
        case "/edge-management/banks":
          updateSideBarTab("banks", "edgeManagement");
          break;
        case "/edge-management/lifts":
          updateSideBarTab("lifts", "edgeManagement");
          break;
        case "/edge-management/configuration-catalog":
          updateSideBarTab("configurationCatalog", "edgeManagement");
          break;
        case "/edge-management/groups":
          updateSideBarTab("groups", "edgeManagement");
          break;
        case "/tools/root-permissions":
          updateSideBarTab("rootPermissions", "tools");
          break;
        case "/tools/offset-type-configuration":
          updateSideBarTab("offsetTypeConfiguration", "tools");
          break;
        case "/tools/special-switches-configuration":
          updateSideBarTab("specialSwitchesConfiguration", "tools");
          break;
        case "/tools/special-switches-catalog-configuration":
          updateSideBarTab("specialSwitchesCatalogConfiguration", "tools");
          break;
        case "/tools/data-blocks-configuration":
          updateSideBarTab("dataBlocksConfiguration", "tools");
          break;
        case "/tools/vendors":
          updateSideBarTab("vendorsConfiguration", "tools");
          break;
        case "/tools/actions":
          updateSideBarTab("actionsConfiguration", "tools");
          break;
        case "/tools/messages-type":
          updateSideBarTab("messageType", "tools");
          break;
        case "/tools/messages":
          updateSideBarTab("message", "tools");
          break;
        case "/notification-management/notifications-banks":
          updateSideBarTab("notificationsBanks", "notificationManagement");
          break;
        case "/notification-management/notifications":
          updateSideBarTab("notifications", "notificationManagement");
          break;
        case "/notification-management/notifications-users":
          updateSideBarTab("notificationsUsers", "notificationManagement");
          break;
        case "/notification-management/notifications-schedules":
          updateSideBarTab("notificationsSchedules", "notificationManagement");
          break;
        case "/tools/icon-definition":
          updateSideBarTab("iconDefinitionConfiguration", "tools");
          break;
        case "/tools/permissions":
          updateSideBarTab("permissions", "tools");
          break;
        case "/reports/report-general":
          updateSideBarTab("reportGeneral", "reports");
          break;
        case "/reports/report-fault":
          updateSideBarTab("reportFault", "reports");
          break;
        case "/reports/general/report-current-out-of-service":
          updateSideBarTabCascader("reportGeneralReportCurrentOutOfService", [
            "reports",
            "general",
          ]);
          break;
        case "/reports/general/report-performance":
          updateSideBarTabCascader("reportGeneralReportPerformance", [
            "reports",
            "general",
          ]);
          break;
        case "/reports/general/report-beneficial-usage":
          updateSideBarTabCascader("reportGeneralReportBeneficialUsage", [
            "reports",
            "general",
          ]);
          break;
        case "/reports/general/report-escalator-in-use":
          updateSideBarTabCascader("reportGeneralReportEscalatorInUse", [
            "reports",
            "general",
          ]);
          break;
        case "/reports/general/report-fire-service":
          updateSideBarTabCascader("reportGeneralReportFireService", [
            "reports",
            "general",
          ]);
          break;
        case "/reports/faults/report-recent":
          updateSideBarTabCascader("reportFaultsReportRecentFault", [
            "reports",
            "faults",
          ]);
          break;
        case "/reports/faults/report-log":
          updateSideBarTabCascader("reportFaultsReportLog", [
            "reports",
            "faults",
          ]);
          break;
        case "/reports/faults/chart-lift":
          updateSideBarTabCascader("reportFaultsChartLift", [
            "reports",
            "faults",
          ]);
          break;
        case "/reports/faults/chart-floor":
          updateSideBarTabCascader("reportFaultsChartFloor", [
            "reports",
            "faults",
          ]);
          break;
        case "/reports/faults/chart-day":
          updateSideBarTabCascader("reportFaultsChartDay", [
            "reports",
            "faults",
          ]);
          break;
        case "/reports/faults/chart-fault":
          updateSideBarTabCascader("reportFaultsChartFault", [
            "reports",
            "faults",
          ]);
          break;
        case "/reports/faults/report-car":
          updateSideBarTabCascader("reportFaultsFrequencyReportCar", [
            "reports",
            "faults",
          ]);
          break;
        case "/reports/faults/report-fault":
          updateSideBarTabCascader("reportFaultsFrequencyReportFault", [
            "reports",
            "faults",
          ]);
          break;
        case "/reports/traffic/chart-hall-button-counts-by-floor":
          updateSideBarTabCascader(
            "reportTrafficChartHallButtonCountsByFloor",
            ["reports", "traffic"]
          );
          break;
        case "/reports/traffic/chart-hall-button-counts-by-time-of-day":
          updateSideBarTabCascader(
            "reportTrafficChartHallButtonCountsByTimeOfDay",
            ["reports", "traffic"]
          );
          break;
        case "/reports/traffic/chart-trips-to-and-from-a-floor":
          updateSideBarTabCascader("reportTrafficChartTripsToAndFromAFloor", [
            "reports",
            "traffic",
          ]);
          break;
        case "/reports/traffic/report-detailed-summary":
          updateSideBarTabCascader("reportTrafficReportDetailedSummary", [
            "reports",
            "traffic",
          ]);
          break;
        case "/reports/traffic/report-hall-call-summary":
          updateSideBarTabCascader("reportTrafficReportHallCallSummaryReport", [
            "reports",
            "traffic",
          ]);
          break;
        case "/reports/traffic/chart-average-by-floor":
          updateSideBarTabCascader("reportTrafficChartAverageByFloor", [
            "reports",
            "traffic",
          ]);
          break;
        case "/reports/traffic/chart-average-by-time-of-day":
          updateSideBarTabCascader("reportTrafficChartAverageByTimeOfDay", [
            "reports",
            "traffic",
          ]);
          break;
        case "/reports/traffic/chart-distribution-by-hourly-interval":
          updateSideBarTabCascader(
            "reportTrafficChartDistributionByHourlyInterval",
            ["reports", "traffic"]
          );
          break;
        case "/reports/traffic/chart-distribution-detailed-total":
          updateSideBarTabCascader(
            "reportTrafficChartDistributionDetailedTotal",
            ["reports", "traffic"]
          );
          break;
        case "/reports/traffic/chart-distribution-detailed-direction":
          updateSideBarTabCascader(
            "reportTrafficChartDistributionDetailedDirection",
            ["reports", "traffic"]
          );
          break;
        case "/reports/traffic/report-longest-wait-time-list":
          updateSideBarTabCascader("reportTrafficReportLongestWaitTimeList", [
            "reports",
            "traffic",
          ]);
          break;
        case "/reports/report-performance":
          updateSideBarTab("reportPerformance", "reports");
          break;
        case "/reports/report-out-of-service":
          updateSideBarTab("reportOutOfService", "reports");
          break;
        case "/reports/report-traffic":
          updateSideBarTab("reportTraffic", "reports");
          break;
        case "/reports/report-car-user":
          updateSideBarTab("reportCarUsage", "reports");
          break;
        case "/reports/car-use/chart-by-hour":
          updateSideBarTabCascader("reportCarUseChartByHour", [
            "reports",
            "carUse",
          ]);
          break;
        case "/reports/car-use/chart-comparisons":
          updateSideBarTabCascader("reportCarUseChartComparisons", [
            "reports",
            "carUse",
          ]);
          break;
        case "/reports/car-use/chart-times":
          updateSideBarTabCascader("reportCarUseChartTimes", [
            "reports",
            "carUse",
          ]);
          break;
        case "/reports/report-service":
          updateSideBarTab("reportService", "reports");
          break;
        case "/reports/car-service/chart-service-log":
          updateSideBarTabCascader("reportCarServiceChartServiceLog", [
            "reports",
            "carService",
          ]);
          break;
        case "/reports/car-service/report-service-log":
          updateSideBarTabCascader("reportCarServiceServiceLog", [
            "reports",
            "carService",
          ]);
          break;
        case "/reports/car-service/report-log":
          updateSideBarTabCascader("reportCarServiceReportLog", [
            "reports",
            "carService",
          ]);
          break;
        case "/reports/car-service/report-statistics":
          updateSideBarTabCascader("reportCarServiceReportStatistics", [
            "reports",
            "carService",
          ]);
          break;

        case "/reports/report-beneficial":
          updateSideBarTab("reportBeneficial", "reports");
          break;
        default:
          break;
      }
    }
  }, [location]);

  useEffect(() => {
    showIsLoading();
    setTimeout(() => {
      setLocalShowFullView(showFullView);
    }, 100);
  }, [showFullView]);

  useEffect(() => {
    if (getCurrentLoggedUserIsError) {
      // signOut();
    }
  }, [getCurrentLoggedUserIsError]);

  // ************************************************ */
  // COMPONENT ************************************** */
  return (
    <Spin spinning={isLoading || getCurrentLoggedUserIsLoading}>
      {!isLoading && (
        <Layout className="generalStyles__layoutHeight">
          {/** ******************************************************* */}
          {/** SIDE BAR */}
          {((!localShowFullView &&
            localStorage.getItem("headerTitle") === "monitoring") ||
            localStorage.getItem("headerTitle") !== "monitoring") && (
            <Sider
              collapsible
              collapsed={sideBarCollapsed || isMobile}
              onCollapse={(value) => dispatch(updateSideBarCollapsed(value))}
              width="290"
            >
              <div
                className="logo"
                role="button"
                tabIndex={0}
                onClick={() => {
                  onClickLiftnetLogo();
                }}
                onKeyDown={() => {}}
                aria-label="Liftnet's logo"
              >
                <img
                  src={
                    isMobile
                      ? mobileLogo
                      : theme === "light"
                      ? logoWhite
                      : logoBlack
                  }
                  alt=""
                  className={
                    isMobile
                      ? "layout__sideBarMobileLogo"
                      : "layout__sideBarLogo"
                  }
                />
              </div>
              <Menu
                defaultSelectedKeys={[current]}
                defaultOpenKeys={openKeys}
                selectedKeys={[current]}
                openKeys={openKeys}
                mode="inline"
                onClick={onChangeTab}
                onOpenChange={onOpenChange}
              >
                {/* -*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*- */}
                {/** MONITORING *********** */}
                <Menu.Item
                  key="monitoring"
                  icon={
                    <FontAwesomeIcon
                      className="generalStyles__info"
                      icon={faChartGantt}
                    />
                  }
                >
                  <Link
                    onClick={() => {
                      handleChangeHeaderTitle("monitoring");
                    }}
                    to="/monitoring"
                  >
                    <span>{t("sideBar.monitoring")}</span>
                  </Link>
                </Menu.Item>
                {/* -*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*- */}
                {/** ORGANIZATION MANAGEMENT *********** */}
                {validatePermission(
                  "view-organization-management",
                  permissions
                ) && (
                  <Menu.SubMenu
                    title={t("sideBar.organizationManagement")}
                    key="organizationManagement"
                    icon={
                      <FontAwesomeIcon
                        className="generalStyles__info"
                        icon={faBuildingUser}
                      />
                    }
                  >
                    {/** ORGANIZATIONS *********** */}
                    {validatePermission(
                      "view-organization-management-organizations",
                      permissions
                    ) && (
                      <Menu.Item
                        key="organizations"
                        icon={
                          <FontAwesomeIcon
                            className="generalStyles__info"
                            icon={faBuilding}
                          />
                        }
                      >
                        <Link
                          onClick={() => {
                            handleChangeHeaderTitle("organizations");
                          }}
                          to="/organization-management/organizations"
                        >
                          <span>{t("sideBar.organizations")}</span>
                        </Link>
                      </Menu.Item>
                    )}
                    {/** ROLES AND PERMISSIONS *********** */}
                    {validatePermission(
                      "view-organization-management-roles",
                      permissions
                    ) && (
                      <Menu.Item
                        key="roles"
                        icon={
                          <FontAwesomeIcon
                            className="generalStyles__info"
                            icon={faUserShield}
                          />
                        }
                      >
                        <Link
                          onClick={() => {
                            handleChangeHeaderTitle("roles");
                          }}
                          to="/organization-management/roles"
                        >
                          <span>{t("sideBar.roles")}</span>
                        </Link>
                      </Menu.Item>
                    )}
                    {/** USERS *********** */}
                    {validatePermission("users", permissions) && (
                      <Menu.Item
                        key="view-organization-management-users"
                        icon={
                          <FontAwesomeIcon
                            className="generalStyles__info"
                            icon={faUsers}
                          />
                        }
                      >
                        <Link
                          onClick={() => {
                            handleChangeHeaderTitle("users");
                          }}
                          to="/users-management/users"
                        >
                          <span>{t("sideBar.users")}</span>
                        </Link>
                      </Menu.Item>
                    )}
                  </Menu.SubMenu>
                )}
                {/* -*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*- */}
                {/** EDGE MANAGEMENT *********** */}
                {validatePermission("view-edge-management", permissions) && (
                  <Menu.SubMenu
                    title={t("header.edgeManagement")}
                    key="edgeManagement"
                    icon={
                      <FontAwesomeIcon
                        className="generalStyles__info"
                        icon={faGears}
                      />
                    }
                  >
                    {/** SERVERS *********** */}
                    {validatePermission(
                      "view-edge-management-servers",
                      permissions
                    ) && (
                      <Menu.Item
                        key="servers"
                        icon={
                          <FontAwesomeIcon
                            className="generalStyles__info"
                            icon={faServer}
                          />
                        }
                      >
                        <Link
                          onClick={() => {
                            handleChangeHeaderTitle("servers");
                          }}
                          to="/edge-management/servers"
                        >
                          <span>{t("sideBar.servers")}</span>
                        </Link>
                      </Menu.Item>
                    )}
                    {/** CHANNELS *********** */}
                    {validatePermission(
                      "view-edge-management-channels",
                      permissions
                    ) && (
                      <Menu.Item
                        key="channels"
                        icon={
                          <FontAwesomeIcon
                            className="generalStyles__info"
                            icon={faEthernet}
                          />
                        }
                      >
                        <Link
                          onClick={() => {
                            handleChangeHeaderTitle("channels");
                            dispatch(updateBreadcrumbTitle("channels"));
                            dispatch(
                              updateServer({
                                id: "",
                                ip: "",
                                name: "",
                                next_records_sync_run: "",
                                next_server_sync_run: "",
                                org_id: "",
                                record_sync_size: 0,
                                synced_at: "",
                                sync_sched: "",
                                channels: [],
                              })
                            );
                            dispatch(updateRenderComponent("channels"));
                          }}
                          to="/edge-management/channels"
                        >
                          <span>{t("sideBar.channels")}</span>
                        </Link>
                      </Menu.Item>
                    )}
                    {/** BANKS *********** */}
                    {validatePermission(
                      "view-edge-management-banks",
                      permissions
                    ) && (
                      <Menu.Item
                        key="banks"
                        icon={
                          <FontAwesomeIcon
                            className="generalStyles__info"
                            icon={faBuildingShield}
                          />
                        }
                      >
                        <Link
                          onClick={() => {
                            handleChangeHeaderTitle("banks");
                          }}
                          to="/edge-management/banks"
                        >
                          <span>{t("sideBar.banks")}</span>
                        </Link>
                      </Menu.Item>
                    )}
                    {/** LIFTS *********** */}
                    {validatePermission(
                      "view-edge-management-lifts",
                      permissions
                    ) && (
                      <Menu.Item
                        key="lifts"
                        icon={
                          <FontAwesomeIcon
                            className="generalStyles__info"
                            icon={faElevator}
                          />
                        }
                      >
                        <Link
                          onClick={() => {
                            handleChangeHeaderTitle("lifts");
                          }}
                          to="/edge-management/lifts"
                        >
                          <span>{t("sideBar.lifts")}</span>
                        </Link>
                      </Menu.Item>
                    )}
                    {/** ADAPTERS *********** */}
                    {validatePermission(
                      "view-edge-management-adapters",
                      permissions
                    ) && (
                      <Menu.Item
                        key="adapters"
                        icon={
                          <FontAwesomeIcon
                            className="generalStyles__info"
                            icon={faPlugCircleCheck}
                          />
                        }
                      >
                        <Link
                          onClick={() => {
                            handleChangeHeaderTitle("adapters");
                          }}
                          to="/edge-management/adapters"
                        >
                          <span>{t("sideBar.adapters")}</span>
                        </Link>
                      </Menu.Item>
                    )}
                    {/** GROUPS *********** */}
                    {validatePermission(
                      "view-edge-management-groups",
                      permissions
                    ) && (
                      <Menu.Item
                        key="groups"
                        icon={
                          <FontAwesomeIcon
                            className="generalStyles__info"
                            icon={faObjectGroup}
                          />
                        }
                      >
                        <Link
                          onClick={() => {
                            handleChangeHeaderTitle("groups");
                          }}
                          to="/edge-management/groups"
                        >
                          <span>{t("sideBar.groups")}</span>
                        </Link>
                      </Menu.Item>
                    )}
                    {/** MAP VIEW *********** */}
                    {validatePermission(
                      "view-edge-management-map",
                      permissions
                    ) && (
                      <Menu.Item
                        key="mapView"
                        icon={
                          <FontAwesomeIcon
                            className="generalStyles__info"
                            icon={faMap}
                          />
                        }
                      >
                        <Link
                          onClick={() => {
                            handleChangeHeaderTitle("mapView");
                          }}
                          to="/edge-management/map-view"
                        >
                          <span>{t("sideBar.mapView")}</span>
                        </Link>
                      </Menu.Item>
                    )}
                    {/** CONFIGURATION CATALOG *********** */}
                    {validatePermission(
                      "view-edge-management-catalog",
                      permissions
                    ) && (
                      <Menu.Item
                        key="configurationCatalog"
                        icon={
                          <FontAwesomeIcon
                            className="generalStyles__info"
                            icon={faFileCircleCheck}
                          />
                        }
                      >
                        <Link
                          onClick={() => {
                            handleChangeHeaderTitle("configurationCatalog");
                          }}
                          to="/edge-management/configuration-catalog"
                        >
                          <span>{t("sideBar.configurationCatalog")}</span>
                        </Link>
                      </Menu.Item>
                    )}
                  </Menu.SubMenu>
                )}
                {/* -*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*- */}
                {/** NOTIFICATION MANAGEMENT *********** */}
                {validatePermission(
                  "view-notification-management",
                  permissions
                ) && (
                  <Menu.SubMenu
                    title={t("general.notificationManagement")}
                    key="notificationManagement"
                    icon={
                      <FontAwesomeIcon
                        className="generalStyles__info"
                        icon={faEnvelopeCircleCheck}
                      />
                    }
                  >
                    {/** BANKS *********** */}
                    {validatePermission(
                      "view-notification-management-banks",
                      permissions
                    ) && (
                      <Menu.Item
                        key="notificationsBanks"
                        icon={
                          <FontAwesomeIcon
                            className="generalStyles__info"
                            icon={faBuilding}
                          />
                        }
                      >
                        <Link
                          onClick={() => {
                            handleChangeHeaderTitle("notificationsBanks");
                          }}
                          to="/notification-management/notifications-banks"
                        >
                          <span>{t("general.banks")}</span>
                        </Link>
                      </Menu.Item>
                    )}
                  </Menu.SubMenu>
                )}
                {/* -*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*- */}
                {/** REPORTS *********** */}
                {validatePermission("view-report-management", permissions) && (
                  <Menu.SubMenu
                    title="Reports"
                    key="reports"
                    icon={
                      <FontAwesomeIcon
                        className="generalStyles__info"
                        icon={faFileCircleCheck}
                      />
                    }
                  >
                    {/** REPORT GENERAL */}
                    <Menu.SubMenu
                      title="General"
                      key="general"
                      icon={
                        <FontAwesomeIcon
                          className="generalStyles__info"
                          icon={faGlobe}
                        />
                      }
                    >
                      {validatePermission(
                        "view-report-management-general-current-out-of-service",
                        permissions
                      ) && (
                        <Menu.Item
                          key="reportGeneralReportCurrentOutOfService"
                          icon={
                            <FontAwesomeIcon
                              className="generalStyles__info"
                              icon={faTable}
                            />
                          }
                        >
                          <Link
                            onClick={() => {
                              handleChangeHeaderTitle(
                                "reportGeneralReportCurrentOutOfService"
                              );
                            }}
                            to="/reports/general/report-current-out-of-service"
                          >
                            <span>{t("general.currentOutOfService")}</span>
                          </Link>
                        </Menu.Item>
                      )}
                      {validatePermission(
                        "view-report-management-general-performance",
                        permissions
                      ) && (
                        <Menu.Item
                          key="reportGeneralReportPerformance"
                          icon={
                            <FontAwesomeIcon
                              className="generalStyles__info"
                              icon={faTable}
                            />
                          }
                        >
                          <Link
                            onClick={() => {
                              handleChangeHeaderTitle(
                                "reportGeneralReportPerformance"
                              );
                            }}
                            to="/reports/general/report-performance"
                          >
                            <span>{t("general.performance")}</span>
                          </Link>
                        </Menu.Item>
                      )}
                      {validatePermission(
                        "view-report-management-general-beneficial-usage",
                        permissions
                      ) && (
                        <Menu.Item
                          key="reportGeneralReportBeneficialUsage"
                          icon={
                            <FontAwesomeIcon
                              className="generalStyles__info"
                              icon={faTable}
                            />
                          }
                        >
                          <Link
                            onClick={() => {
                              handleChangeHeaderTitle(
                                "reportGeneralReportBeneficialUsage"
                              );
                            }}
                            to="/reports/general/report-beneficial-usage"
                          >
                            <span>{t("general.beneficialUsage")}</span>
                          </Link>
                        </Menu.Item>
                      )}
                      {validatePermission(
                        "view-report-management-general-escalator-in-use",
                        permissions
                      ) && (
                        <Menu.Item
                          key="reportGeneralReportEscalatorInUse"
                          icon={
                            <FontAwesomeIcon
                              className="generalStyles__info"
                              icon={faTable}
                            />
                          }
                        >
                          <Link
                            onClick={() => {
                              handleChangeHeaderTitle(
                                "reportGeneralReportEscalatorInUse"
                              );
                            }}
                            to="/reports/general/report-escalator-in-use"
                          >
                            <span>{t("general.escalatorInUse")}</span>
                          </Link>
                        </Menu.Item>
                      )}
                      {validatePermission(
                        "view-report-management-general-fire-service",
                        permissions
                      ) && (
                        <Menu.Item
                          key="reportGeneralReportFireService"
                          icon={
                            <FontAwesomeIcon
                              className="generalStyles__info"
                              icon={faTable}
                            />
                          }
                        >
                          <Link
                            onClick={() => {
                              handleChangeHeaderTitle(
                                "reportGeneralReportFireService"
                              );
                            }}
                            to="/reports/general/report-fire-service"
                          >
                            <span>{t("general.fireService")}</span>
                          </Link>
                        </Menu.Item>
                      )}
                    </Menu.SubMenu>
                    {/** REPORT FAULT */}
                    <Menu.SubMenu
                      title="Faults"
                      key="faults"
                      icon={
                        <FontAwesomeIcon
                          className="generalStyles__info"
                          icon={faTriangleExclamation}
                        />
                      }
                    >
                      {validatePermission(
                        "view-report-management-faults-recent-faults",
                        permissions
                      ) && (
                        <Menu.Item
                          key="reportFaultsReportRecentFault"
                          icon={
                            <FontAwesomeIcon
                              className="generalStyles__info"
                              icon={faTable}
                            />
                          }
                        >
                          <Link
                            onClick={() => {
                              handleChangeHeaderTitle(
                                "reportFaultsReportRecent"
                              );
                            }}
                            to="/reports/faults/report-recent"
                          >
                            <span>{t("general.recentFaults")}</span>
                          </Link>
                        </Menu.Item>
                      )}
                      {validatePermission(
                        "view-report-management-faults-log-by-bank",
                        permissions
                      ) && (
                        <Menu.Item
                          key="reportFaultsReportLog"
                          icon={
                            <FontAwesomeIcon
                              className="generalStyles__info"
                              icon={faTable}
                            />
                          }
                        >
                          <Link
                            onClick={() => {
                              handleChangeHeaderTitle("reportFaultsReportLog");
                            }}
                            to="/reports/faults/report-log"
                          >
                            <span>{t("general.faultLogByBank")}</span>
                          </Link>
                        </Menu.Item>
                      )}
                      {validatePermission(
                        "view-report-management-faults-frequency-by-car",
                        permissions
                      ) && (
                        <Menu.Item
                          key="reportFaultsFrequencyReportCar"
                          icon={
                            <FontAwesomeIcon
                              className="generalStyles__info"
                              icon={faTable}
                            />
                          }
                        >
                          <Link
                            onClick={() => {
                              handleChangeHeaderTitle(
                                "reportFaultsFrequencyReportCar"
                              );
                            }}
                            to="/reports/faults/report-car"
                          >
                            <span>{t("general.frequencyByCar")}</span>
                          </Link>
                        </Menu.Item>
                      )}
                      {validatePermission(
                        "view-report-management-faults-frequency-by-fault",
                        permissions
                      ) && (
                        <Menu.Item
                          key="reportFaultsFrequencyReportFault"
                          icon={
                            <FontAwesomeIcon
                              className="generalStyles__info"
                              icon={faTable}
                            />
                          }
                        >
                          <Link
                            onClick={() => {
                              handleChangeHeaderTitle(
                                "reportFaultsFrequencyReportFault"
                              );
                            }}
                            to="/reports/faults/report-fault"
                          >
                            <span>{t("general.frequencyByFault")}</span>
                          </Link>
                        </Menu.Item>
                      )}
                      {validatePermission(
                        "view-report-management-faults-by-car",
                        permissions
                      ) && (
                        <Menu.Item
                          key="reportFaultsChartLift"
                          icon={
                            <FontAwesomeIcon
                              className="generalStyles__info"
                              icon={faChartPie}
                            />
                          }
                        >
                          <Link
                            onClick={() => {
                              handleChangeHeaderTitle("reportFaultsChartLift");
                            }}
                            to="/reports/faults/chart-lift"
                          >
                            <span>{t("general.faultByLift")}</span>
                          </Link>
                        </Menu.Item>
                      )}
                      {validatePermission(
                        "view-report-management-faults-by-floor",
                        permissions
                      ) && (
                        <Menu.Item
                          key="reportFaultsChartFloor"
                          icon={
                            <FontAwesomeIcon
                              className="generalStyles__info"
                              icon={faChartPie}
                            />
                          }
                        >
                          <Link
                            onClick={() => {
                              handleChangeHeaderTitle("reportFaultsChartFloor");
                            }}
                            to="/reports/faults/chart-floor"
                          >
                            <span>{t("general.faultByFloor")}</span>
                          </Link>
                        </Menu.Item>
                      )}
                      {validatePermission(
                        "view-report-management-faults-by-day-week",
                        permissions
                      ) && (
                        <Menu.Item
                          key="reportFaultsChartDay"
                          icon={
                            <FontAwesomeIcon
                              className="generalStyles__info"
                              icon={faChartPie}
                            />
                          }
                        >
                          <Link
                            onClick={() => {
                              handleChangeHeaderTitle("reportFaultsChartDay");
                            }}
                            to="/reports/faults/chart-day"
                          >
                            <span>{t("general.faultByDay")}</span>
                          </Link>
                        </Menu.Item>
                      )}
                      {validatePermission(
                        "view-report-management-faults-by-type",
                        permissions
                      ) && (
                        <Menu.Item
                          key="reportFaultsChartFault"
                          icon={
                            <FontAwesomeIcon
                              className="generalStyles__info"
                              icon={faChartPie}
                            />
                          }
                        >
                          <Link
                            onClick={() => {
                              handleChangeHeaderTitle("reportFaultsChartFault");
                            }}
                            to="/reports/faults/chart-fault"
                          >
                            <span>{t("general.faultByType")}</span>
                          </Link>
                        </Menu.Item>
                      )}
                    </Menu.SubMenu>
                    {/** REPORT TRAFFIC */}
                    <Menu.SubMenu
                      title="Traffic"
                      key="traffic"
                      icon={
                        <FontAwesomeIcon
                          className="generalStyles__info"
                          icon={faUpDown}
                        />
                      }
                    >
                      {validatePermission(
                        "view-report-management-traffic-detailed-summary-report",
                        permissions
                      ) && (
                        <Menu.Item
                          key="reportTrafficReportDetailedSummary"
                          icon={
                            <FontAwesomeIcon
                              className="generalStyles__info"
                              icon={faTable}
                            />
                          }
                        >
                          <Link
                            onClick={() => {
                              handleChangeHeaderTitle(
                                "reportTrafficReportDetailedSummary"
                              );
                            }}
                            to="/reports/traffic/report-detailed-summary"
                          >
                            <span>{t("general.detailedSummaryReport")}</span>
                          </Link>
                        </Menu.Item>
                      )}
                      {validatePermission(
                        "view-report-management-traffic-hall-call-summary-report",
                        permissions
                      ) && (
                        <Menu.Item
                          key="reportTrafficReportHallCallSummaryReport"
                          icon={
                            <FontAwesomeIcon
                              className="generalStyles__info"
                              icon={faTable}
                            />
                          }
                        >
                          <Link
                            onClick={() => {
                              handleChangeHeaderTitle(
                                "reportTrafficReportHallCallSummaryReport"
                              );
                            }}
                            to="/reports/traffic/report-hall-call-summary"
                          >
                            <span>{t("general.hallCallSummaryReport")}</span>
                          </Link>
                        </Menu.Item>
                      )}
                      {validatePermission(
                        "view-report-management-traffic-longest-wait-times-list",
                        permissions
                      ) && (
                        <Menu.Item
                          key="reportTrafficReportLongestWaitTimeList"
                          icon={
                            <FontAwesomeIcon
                              className="generalStyles__info"
                              icon={faTable}
                            />
                          }
                        >
                          <Link
                            onClick={() => {
                              handleChangeHeaderTitle(
                                "reportTrafficReportLongestWaitTimeList"
                              );
                            }}
                            to="/reports/traffic/report-longest-wait-time-list"
                          >
                            <span>{t("general.longestWaitTimes")}</span>
                          </Link>
                        </Menu.Item>
                      )}
                      {validatePermission(
                        "view-report-management-traffic-trips-to-and-from-floor",
                        permissions
                      ) && (
                        <Menu.Item
                          key="reportTrafficChartTripsToAndFromAFloor"
                          icon={
                            <FontAwesomeIcon
                              className="generalStyles__info"
                              icon={faPieChart}
                            />
                          }
                        >
                          <Link
                            onClick={() => {
                              handleChangeHeaderTitle(
                                "reportTrafficChartTripsToAndFromAFloor"
                              );
                            }}
                            to="/reports/traffic/chart-trips-to-and-from-a-floor"
                          >
                            <span>{t("general.tripsToAndFromFloor")}</span>
                          </Link>
                        </Menu.Item>
                      )}
                      {validatePermission(
                        "view-report-management-traffic-hall-button-counts-by-floor",
                        permissions
                      ) && (
                        <Menu.Item
                          key="reportTrafficChartHallButtonCountsByFloor"
                          icon={
                            <FontAwesomeIcon
                              className="generalStyles__info"
                              icon={faPieChart}
                            />
                          }
                        >
                          <Link
                            onClick={() => {
                              handleChangeHeaderTitle(
                                "reportTrafficChartHallButtonCountsByFloor"
                              );
                            }}
                            to="/reports/traffic/chart-hall-button-counts-by-floor"
                          >
                            <span>{t("general.byFloor")}</span>
                          </Link>
                        </Menu.Item>
                      )}
                      {validatePermission(
                        "view-report-management-traffic-hall-button-count-by-time-of-day",
                        permissions
                      ) && (
                        <Menu.Item
                          key="reportTrafficChartHallButtonCountsByTimeOfDay"
                          icon={
                            <FontAwesomeIcon
                              className="generalStyles__info"
                              icon={faPieChart}
                            />
                          }
                        >
                          <Link
                            onClick={() => {
                              handleChangeHeaderTitle(
                                "reportTrafficChartHallButtonCountsByTimeOfDay"
                              );
                            }}
                            to="/reports/traffic/chart-hall-button-counts-by-time-of-day"
                          >
                            <span>{t("general.byTimeOfDay")}</span>
                          </Link>
                        </Menu.Item>
                      )}
                      {validatePermission(
                        "view-report-management-traffic-average-by-floor",
                        permissions
                      ) && (
                        <Menu.Item
                          key="reportTrafficChartAverageByFloor"
                          icon={
                            <FontAwesomeIcon
                              className="generalStyles__info"
                              icon={faPieChart}
                            />
                          }
                        >
                          <Link
                            onClick={() => {
                              handleChangeHeaderTitle(
                                "reportTrafficChartAverageByFloor"
                              );
                            }}
                            to="/reports/traffic/chart-average-by-floor"
                          >
                            <span>{t("general.averageByFloor")}</span>
                          </Link>
                        </Menu.Item>
                      )}
                      {validatePermission(
                        "view-report-management-traffic-average-by-time-of-day",
                        permissions
                      ) && (
                        <Menu.Item
                          key="reportTrafficChartAverageByTimeOfDay"
                          icon={
                            <FontAwesomeIcon
                              className="generalStyles__info"
                              icon={faPieChart}
                            />
                          }
                        >
                          <Link
                            onClick={() => {
                              handleChangeHeaderTitle(
                                "reportTrafficChartAverageByTimeOfDay"
                              );
                            }}
                            to="/reports/traffic/chart-average-by-time-of-day"
                          >
                            <span>{t("general.averageByTimeOfDay")}</span>
                          </Link>
                        </Menu.Item>
                      )}
                      {validatePermission(
                        "view-report-management-traffic-distribution-by-hourly-interval",
                        permissions
                      ) && (
                        <Menu.Item
                          key="reportTrafficChartDistributionByHourlyInterval"
                          icon={
                            <FontAwesomeIcon
                              className="generalStyles__info"
                              icon={faPieChart}
                            />
                          }
                        >
                          <Link
                            onClick={() => {
                              handleChangeHeaderTitle(
                                "reportTrafficChartDistributionByHourlyInterval"
                              );
                            }}
                            to="/reports/traffic/chart-distribution-by-hourly-interval"
                          >
                            <span>
                              {t("general.distributionByHourlyInterval")}
                            </span>
                          </Link>
                        </Menu.Item>
                      )}
                      {validatePermission(
                        "view-report-management-traffic-distribution-detailed-total",
                        permissions
                      ) && (
                        <Menu.Item
                          key="reportTrafficChartDistributionDetailedTotal"
                          icon={
                            <FontAwesomeIcon
                              className="generalStyles__info"
                              icon={faPieChart}
                            />
                          }
                        >
                          <Link
                            onClick={() => {
                              handleChangeHeaderTitle(
                                "reportTrafficChartDistributionDetailedTotal"
                              );
                            }}
                            to="/reports/traffic/chart-distribution-detailed-total"
                          >
                            <span>
                              {t("general.distributionDetailedTotal")}
                            </span>
                          </Link>
                        </Menu.Item>
                      )}
                      {validatePermission(
                        "view-report-management-traffic-distribution-detaile-direction",
                        permissions
                      ) && (
                        <Menu.Item
                          key="reportTrafficChartDistributionDetailedDirection"
                          icon={
                            <FontAwesomeIcon
                              className="generalStyles__info"
                              icon={faPieChart}
                            />
                          }
                        >
                          <Link
                            onClick={() => {
                              handleChangeHeaderTitle(
                                "reportTrafficChartDistributionDetailedDirection"
                              );
                            }}
                            to="/reports/traffic/chart-distribution-detailed-direction"
                          >
                            <span>
                              {t("general.distributionDetailedDirection")}
                            </span>
                          </Link>
                        </Menu.Item>
                      )}
                    </Menu.SubMenu>
                    {/** REPORT CAR USE */}
                    <Menu.SubMenu
                      title="Car Use"
                      key="carUse"
                      icon={
                        <FontAwesomeIcon
                          className="generalStyles__info"
                          icon={faElevator}
                        />
                      }
                    >
                      {validatePermission(
                        "view-report-management-car-use-by-hour",
                        permissions
                      ) && (
                        <Menu.Item
                          key="reportCarUseChartByHour"
                          icon={
                            <FontAwesomeIcon
                              className="generalStyles__info"
                              icon={faChartPie}
                            />
                          }
                        >
                          <Link
                            onClick={() => {
                              handleChangeHeaderTitle(
                                "reportCarUseChartByHour"
                              );
                            }}
                            to="/reports/car-use/chart-by-hour"
                          >
                            <span>{t("general.carUseByHour")}</span>
                          </Link>
                        </Menu.Item>
                      )}
                      {validatePermission(
                        "view-report-management-car-use-comparisons",
                        permissions
                      ) && (
                        <Menu.Item
                          key="reportCarUseChartComparisons"
                          icon={
                            <FontAwesomeIcon
                              className="generalStyles__info"
                              icon={faChartPie}
                            />
                          }
                        >
                          <Link
                            onClick={() => {
                              handleChangeHeaderTitle(
                                "reportCarUseChartComparisons"
                              );
                            }}
                            to="/reports/car-use/chart-comparisons"
                          >
                            <span>{t("general.carUseComparisons")}</span>
                          </Link>
                        </Menu.Item>
                      )}
                      {validatePermission(
                        "view-report-management-car-use-door-times",
                        permissions
                      ) && (
                        <Menu.Item
                          key="reportCarUseChartTimes"
                          icon={
                            <FontAwesomeIcon
                              className="generalStyles__info"
                              icon={faChartPie}
                            />
                          }
                        >
                          <Link
                            onClick={() => {
                              handleChangeHeaderTitle("reportCarUseChartTimes");
                            }}
                            to="/reports/car-use/chart-times"
                          >
                            <span>{t("general.carUseTimes")}</span>
                          </Link>
                        </Menu.Item>
                      )}
                    </Menu.SubMenu>
                    {/** REPORT CAR SERVICE */}
                    <Menu.SubMenu
                      title="Car Service"
                      key="carService"
                      icon={
                        <FontAwesomeIcon
                          className="generalStyles__info"
                          icon={faTools}
                        />
                      }
                    >
                      {validatePermission(
                        "view-report-management-car-service-service-log-chart",
                        permissions
                      ) && (
                        <Menu.Item
                          key="reportCarServiceChartServiceLog"
                          icon={
                            <FontAwesomeIcon
                              className="generalStyles__info"
                              icon={faChartPie}
                            />
                          }
                        >
                          <Link
                            onClick={() => {
                              handleChangeHeaderTitle(
                                "reportCarServiceChartServiceLog"
                              );
                            }}
                            to="/reports/car-service/chart-service-log"
                          >
                            <span>{t("general.serviceLog")}</span>
                          </Link>
                        </Menu.Item>
                      )}
                      {validatePermission(
                        "view-report-management-car-service-service-log-table",
                        permissions
                      ) && (
                        <Menu.Item
                          key="reportCarServiceServiceLog"
                          icon={
                            <FontAwesomeIcon
                              className="generalStyles__info"
                              icon={faTable}
                            />
                          }
                        >
                          <Link
                            onClick={() => {
                              handleChangeHeaderTitle(
                                "reportCarServiceServiceLog"
                              );
                            }}
                            to="/reports/car-service/report-service-log"
                          >
                            <span>{t("general.serviceLog")}</span>
                          </Link>
                        </Menu.Item>
                      )}

                      {validatePermission(
                        "view-report-management-car-service-log",
                        permissions
                      ) && (
                        <Menu.Item
                          key="reportCarServiceReportLog"
                          icon={
                            <FontAwesomeIcon
                              className="generalStyles__info"
                              icon={faTable}
                            />
                          }
                        >
                          <Link
                            onClick={() => {
                              handleChangeHeaderTitle(
                                "reportCarServiceReportLog"
                              );
                            }}
                            to="/reports/car-service/report-log"
                          >
                            <span>{t("general.log")}</span>
                          </Link>
                        </Menu.Item>
                      )}
                      {validatePermission(
                        "view-report-management-car-service-statistics",
                        permissions
                      ) && (
                        <Menu.Item
                          key="reportCarServiceReportStatistics"
                          icon={
                            <FontAwesomeIcon
                              className="generalStyles__info"
                              icon={faTable}
                            />
                          }
                        >
                          <Link
                            onClick={() => {
                              handleChangeHeaderTitle(
                                "reportCarServiceReportStatistics"
                              );
                            }}
                            to="/reports/car-service/report-statistics"
                          >
                            <span>{t("general.statistics")}</span>
                          </Link>
                        </Menu.Item>
                      )}
                    </Menu.SubMenu>
                  </Menu.SubMenu>
                )}
                {/* -*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*- */}
                {/** TOOLS *********** */}
                {validatePermission("view-tool-management", permissions) && (
                  <Menu.SubMenu
                    title="Tools"
                    key="tools"
                    icon={
                      <FontAwesomeIcon
                        className="generalStyles__info"
                        icon={faTools}
                      />
                    }
                  >
                    {validatePermission(
                      "view-tool-management-root-permissions",
                      permissions
                    ) && (
                      <>
                        {/** ROOT PERMISSIONS *********** */}
                        <Menu.Item
                          key="rootPermissions"
                          icon={
                            <FontAwesomeIcon
                              className="generalStyles__info"
                              icon={faShieldHalved}
                            />
                          }
                        >
                          <Link
                            onClick={() => {
                              handleChangeHeaderTitle("rootPermissions");
                            }}
                            to="/tools/root-permissions"
                          >
                            <span>{t("sideBar.rootPermissions")}</span>
                          </Link>
                        </Menu.Item>
                      </>
                    )}
                    {validatePermission(
                      "view-tool-management-offset-types",
                      permissions
                    ) && (
                      <>
                        {/** OFFSET TYPE CONFIGURATION *********** */}
                        <Menu.Item
                          key="offsetTypeConfiguration"
                          icon={
                            <FontAwesomeIcon
                              className="generalStyles__info"
                              icon={faShieldHalved}
                            />
                          }
                        >
                          <Link
                            onClick={() => {
                              handleChangeHeaderTitle(
                                "offsetTypeConfiguration"
                              );
                            }}
                            to="/tools/offset-type-configuration"
                          >
                            <span>{t("sideBar.offsetTypeConfiguration")}</span>
                          </Link>
                        </Menu.Item>
                      </>
                    )}
                    {validatePermission(
                      "view-tool-management-special-switch",
                      permissions
                    ) && (
                      <>
                        {/** SPECIAL SWITCHES CONFIGURATION *********** */}
                        <Menu.Item
                          key="specialSwitchesConfiguration"
                          icon={
                            <FontAwesomeIcon
                              className="generalStyles__info"
                              icon={faShieldHalved}
                            />
                          }
                        >
                          <Link
                            onClick={() => {
                              handleChangeHeaderTitle(
                                "specialSwitchesConfiguration"
                              );
                            }}
                            to="/tools/special-switches-configuration"
                          >
                            <span>
                              {t("sideBar.specialSwitchesConfiguration")}
                            </span>
                          </Link>
                        </Menu.Item>
                      </>
                    )}
                    {validatePermission(
                      "view-tool-management-special-switch-catalog",
                      permissions
                    ) && (
                      <>
                        {/** SPECIAL SWITCHES CATALOG CONFIGURATION *********** */}
                        <Menu.Item
                          key="specialSwitchesCatalogConfiguration"
                          icon={
                            <FontAwesomeIcon
                              className="generalStyles__info"
                              icon={faShieldHalved}
                            />
                          }
                        >
                          <Link
                            onClick={() => {
                              handleChangeHeaderTitle(
                                "specialSwitchesCatalogConfiguration"
                              );
                            }}
                            to="/tools/special-switches-catalog-configuration"
                          >
                            <span>
                              {t("sideBar.specialSwitchesCatalogConfiguration")}
                            </span>
                          </Link>
                        </Menu.Item>
                      </>
                    )}
                    {validatePermission(
                      "view-tool-management-data-blocks",
                      permissions
                    ) && (
                      <>
                        {/** DATA BLOCKS CONFIGURATION *********** */}
                        <Menu.Item
                          key="dataBlocksConfiguration"
                          icon={
                            <FontAwesomeIcon
                              className="generalStyles__info"
                              icon={faShieldHalved}
                            />
                          }
                        >
                          <Link
                            onClick={() => {
                              handleChangeHeaderTitle(
                                "dataBlocksConfiguration"
                              );
                            }}
                            to="/tools/data-blocks-configuration"
                          >
                            <span>{t("sideBar.dataBlocks")}</span>
                          </Link>
                        </Menu.Item>
                      </>
                    )}
                    {validatePermission(
                      "view-tool-management-vendors",
                      permissions
                    ) && (
                      <>
                        {/** VENDORS CONFIGURATION *********** */}
                        <Menu.Item
                          key="vendorsConfiguration"
                          icon={
                            <FontAwesomeIcon
                              className="generalStyles__info"
                              icon={faShieldHalved}
                            />
                          }
                        >
                          <Link
                            onClick={() => {
                              handleChangeHeaderTitle("vendorsConfiguration");
                            }}
                            to="/tools/vendors"
                          >
                            <span>{t("sideBar.vendors")}</span>
                          </Link>
                        </Menu.Item>
                      </>
                    )}
                    {validatePermission(
                      "view-tool-management-icon-definition",
                      permissions
                    ) && (
                      <>
                        {/** ICON DEFINITION CONFIGURATION *********** */}
                        <Menu.Item
                          key="iconDefinitionConfiguration"
                          icon={
                            <FontAwesomeIcon
                              className="generalStyles__info"
                              icon={faShieldHalved}
                            />
                          }
                        >
                          <Link
                            onClick={() => {
                              handleChangeHeaderTitle(
                                "iconDefinitionConfiguration"
                              );
                            }}
                            to="/tools/icon-definition"
                          >
                            <span>{t("sideBar.iconDefinition")}</span>
                          </Link>
                        </Menu.Item>
                      </>
                    )}
                    {validatePermission(
                      "view-tool-management-permissions",
                      permissions
                    ) && (
                      <>
                        {/** PERMISSIONS *********** */}
                        <Menu.Item
                          key="permissions"
                          icon={
                            <FontAwesomeIcon
                              className="generalStyles__info"
                              icon={faShieldHalved}
                            />
                          }
                        >
                          <Link
                            onClick={() => {
                              handleChangeHeaderTitle("permissions");
                            }}
                            to="/tools/permissions"
                          >
                            <span>{t("sideBar.permissions")}</span>
                          </Link>
                        </Menu.Item>
                      </>
                    )}
                    {validatePermission(
                      "view-tool-management-actions",
                      permissions
                    ) && (
                      <>
                        {/** ACTIONS CONFIGURATION *********** */}
                        <Menu.Item
                          key="actionsConfiguration"
                          icon={
                            <FontAwesomeIcon
                              className="generalStyles__info"
                              icon={faShieldHalved}
                            />
                          }
                        >
                          <Link
                            onClick={() => {
                              handleChangeHeaderTitle("actions");
                            }}
                            to="/tools/actions"
                          >
                            <span>{t("sideBar.actions")}</span>
                          </Link>
                        </Menu.Item>
                      </>
                    )}
                    {validatePermission(
                      "view-tool-management-messages-type",
                      permissions
                    ) && (
                      <>
                        {/** MESSAGE TYPE CONFIGURATION *********** */}
                        <Menu.Item
                          key="messageType"
                          icon={
                            <FontAwesomeIcon
                              className="generalStyles__info"
                              icon={faShieldHalved}
                            />
                          }
                        >
                          <Link
                            onClick={() => {
                              handleChangeHeaderTitle("messagesTypes");
                            }}
                            to="/tools/messages-type"
                          >
                            <span>{t("sideBar.messageType")}</span>
                          </Link>
                        </Menu.Item>
                      </>
                    )}
                    {validatePermission(
                      "view-tool-management-messages",
                      permissions
                    ) && (
                      <>
                        {/** MESSAGE CONFIGURATION *********** */}
                        <Menu.Item
                          key="messages"
                          icon={
                            <FontAwesomeIcon
                              className="generalStyles__info"
                              icon={faShieldHalved}
                            />
                          }
                        >
                          <Link
                            onClick={() => {
                              handleChangeHeaderTitle("messages");
                            }}
                            to="/tools/messages"
                          >
                            <span>{t("sideBar.message")}</span>
                          </Link>
                        </Menu.Item>
                      </>
                    )}
                  </Menu.SubMenu>
                )}
              </Menu>
            </Sider>
          )}
          {/** ******************************************************* */}
          {/** LAYOUT */}
          <Layout
            style={{
              overflowX: "auto",
            }}
          >
            {/** ******************************************************* */}
            {/** HEADER */}
            {((!localShowFullView &&
              localStorage.getItem("headerTitle") === "monitoring") ||
              localStorage.getItem("headerTitle") !== "monitoring") && (
              <Header className="generalStyles__header">
                <div className="generalStyles__spaceBetween">
                  <div />
                  <div>
                    <YourProfileDrawer />
                  </div>
                </div>
              </Header>
            )}
            {/** ******************************************************* */}
            {/** CONTENT */}
            <Content>
              <LayoutBreadcrumb />
              <div
                className={
                  isMobile ? "layout__contentPageMobile" : "layout__contentPage"
                }
              >
                <Routes>
                  <Route path="*" element={<NotFoundPage />} />
                  <Route path="" element={<MonitoringPage />} />
                  <Route path="monitoring" element={<MonitoringPage />} />
                  <Route path="map" element={<MapViewPage />} />
                  <Route path="users" element={<UsersPage />} />
                  <Route path="organizations" element={<OrganizationsPage />} />
                  <Route path="roles" element={<RolesPage />} />
                  <Route
                    path="root-permissions"
                    element={<RootPermissionsPage />}
                  />
                  <Route
                    path="offset-type-configuration"
                    element={<OffsetTypePage />}
                  />
                  <Route
                    path="special-switches-configuration"
                    element={<SpecialSwitchesPage />}
                  />
                  <Route
                    path="special-switches-catalog-configuration"
                    element={<SpecialSwitchesCatalogPage />}
                  />
                  <Route
                    path="data-blocks-configuration"
                    element={<DataBlocksPage />}
                  />
                  <Route path="vendors" element={<VendorsPage />} />
                  <Route path="actions" element={<ActionsPage />} />
                  <Route path="messages-type" element={<MessageTypePage />} />
                  <Route path="messages" element={<MessagesPage />} />
                  <Route
                    path="notifications-banks"
                    element={<NotificationBanksPage />}
                  />
                  <Route path="notifications" element={<NotificationsPage />} />
                  <Route
                    path="notifications-users"
                    element={<NotificationUsersPage />}
                  />
                  <Route
                    path="notifications-schedules"
                    element={<NotificationSchedulesPage />}
                  />
                  <Route
                    path="icon-definition"
                    element={<IconDefinitionPage />}
                  />
                  <Route path="permissions" element={<PermissionsPage />} />
                  <Route
                    path="configuration-catalog"
                    element={<ConfigurationFiles />}
                  />
                  <Route
                    path="configuration-catalog/definitions"
                    element={<DefinitionsPage />}
                  />
                  <Route
                    path="configuration-catalog/offsets"
                    element={<OffsetsPage />}
                  />
                  <Route path="banks" element={<BanksPage />} />
                  <Route path="servers" element={<ServersPage />} />
                  <Route path="lifts" element={<LiftsPage />} />
                  <Route path="adapters" element={<AdaptersPage />} />
                  <Route path="map-view" element={<MapViewPage />} />
                  <Route
                    path="map-view/configuration"
                    element={<MapViewConfigurationPage />}
                  />
                  <Route path="servers/channels" element={<ChannelsPage />} />
                  <Route path="channels" element={<ChannelsPage />} />
                  <Route path="groups" element={<GroupsPage />} />
                  <Route
                    path="report-general"
                    element={<ReportGeneralPage />}
                  />
                  <Route path="report-fault" element={<ReportFaultGeneral />} />
                  <Route
                    path="general/report-current-out-of-service"
                    element={<ReportGeneralCurrentOutOfService />}
                  />
                  <Route
                    path="general/report-performance"
                    element={<ReportGeneralPerformance />}
                  />
                  <Route
                    path="general/report-beneficial-usage"
                    element={<ReportGeneralBeneficialUsage />}
                  />
                  <Route
                    path="general/report-escalator-in-use"
                    element={<ReportGeneralEscalatorInUse />}
                  />
                  <Route
                    path="general/report-fire-service"
                    element={<ReportGeneralFireService />}
                  />
                  <Route
                    path="faults/report-log"
                    element={<ReportFaultLog />}
                  />
                  <Route
                    path="faults/report-recent"
                    element={<ReportFaultRecent />}
                  />
                  <Route
                    path="faults/chart-day"
                    element={<ReportFaultChartDay />}
                  />
                  <Route
                    path="faults/chart-floor"
                    element={<ReportFaultChartFloor />}
                  />
                  <Route
                    path="faults/chart-lift"
                    element={<ReportFaultChartLift />}
                  />
                  <Route
                    path="faults/chart-fault"
                    element={<ReportFaultChartFault />}
                  />
                  <Route
                    path="faults/report-car"
                    element={<ReportFaultFrequencyCar />}
                  />
                  <Route
                    path="faults/report-fault"
                    element={<ReportFaultFrequencyFault />}
                  />
                  <Route
                    path="traffic/chart-hall-button-counts-by-floor"
                    element={<ReportTrafficChartHallButtonCountsByFloor />}
                  />
                  <Route
                    path="traffic/chart-hall-button-counts-by-time-of-day"
                    element={<ReportTrafficChartHallButtonCountsByTimeOfDay />}
                  />
                  <Route
                    path="traffic/chart-trips-to-and-from-a-floor"
                    element={<ReportTrafficChartTripsToAndFromAFloor />}
                  />
                  <Route
                    path="traffic/report-detailed-summary"
                    element={<ReportTrafficDetailedSummary />}
                  />
                  <Route
                    path="traffic/report-hall-call-summary"
                    element={<ReportTrafficHallCallSummary />}
                  />
                  <Route
                    path="traffic/chart-average-by-floor"
                    element={<ReportTrafficChartAverageByFloor />}
                  />
                  <Route
                    path="traffic/chart-average-by-time-of-day"
                    element={<ReportTrafficChartAverageByTimeOfDay />}
                  />
                  <Route
                    path="traffic/chart-distribution-by-hourly-interval"
                    element={<ReportTrafficChartDistributionByHourlyInterval />}
                  />
                  <Route
                    path="traffic/chart-distribution-detailed-total"
                    element={<ReportTrafficChartDistributionDetailedTotal />}
                  />
                  <Route
                    path="traffic/chart-distribution-detailed-direction"
                    element={
                      <ReportTrafficChartDistributionDetailedDirection />
                    }
                  />
                  <Route
                    path="traffic/report-longest-wait-time-list"
                    element={<ReportTrafficLongestWaitTimeList />}
                  />

                  <Route
                    path="report-performance"
                    element={<ReportPerformancePage />}
                  />
                  <Route
                    path="report-out-of-service"
                    element={<ReportOutOfServicePage />}
                  />
                  <Route
                    path="report-traffic"
                    element={<ReportTrafficGeneral />}
                  />
                  <Route
                    path="report-car-user"
                    element={<ReportCarUsagePage />}
                  />
                  <Route
                    path="car-use/chart-by-hour"
                    element={<ReportCarUseChartByHour />}
                  />
                  <Route
                    path="car-use/chart-comparisons"
                    element={<ReportCarUseChartComparisons />}
                  />
                  <Route
                    path="car-use/chart-times"
                    element={<ReportCarUseChartTimes />}
                  />
                  <Route
                    path="report-service"
                    element={<ReportCarServiceGeneral />}
                  />
                  <Route
                    path="car-service/chart-service-log"
                    element={<ReportCarServiceChartServiceLog />}
                  />
                  <Route
                    path="car-service/report-service-log"
                    element={<ReportCarServiceServiceLog />}
                  />
                  <Route
                    path="car-service/report-log"
                    element={<ReportCarServiceLog />}
                  />
                  <Route
                    path="car-service/report-statistics"
                    element={<ReportCarServiceStatistics />}
                  />
                  <Route
                    path="report-beneficial"
                    element={<ReportBeneficialPage />}
                  />
                </Routes>
                <GlobalAlert />
              </div>
            </Content>
            {/** ******************************************************* */}
            {/** FOOTER */}
            <Footer className="layout__footerText">
              <span
                className={
                  isMobile
                    ? "layout__footerTextMobile"
                    : "layout__footerTextDesktop"
                }
              >
                © {new Date().getFullYear()} powered by - LiftNet
              </span>
            </Footer>
          </Layout>
        </Layout>
      )}
      {!isLoading &&
        !getCurrentLoggedUserIsLoading &&
        userLogged &&
        userLogged.id && <TermsAndConditions />}
      {!isLoading && !getCurrentLoggedUserIsLoading && !userLogged.id && (
        <UserNotFound />
      )}
      {isLoading && <div style={{ height: "50vh" }} />}
    </Spin>
  );
};

export default App;
