import React, { useEffect, useState } from "react";
// ANT DESIGN COMPONENTS
import { Drawer, Spin, Divider, Tag, Select, InputNumber, Tooltip, Empty, Button } from "antd";
// FONT AWESOME LIBRARY AND ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear, faPlugCircleCheck, faTrash } from "@fortawesome/free-solid-svg-icons";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// REDUX
import { useDispatch, useSelector } from "react-redux";
import { updateOpenDrawerAssignActions } from "../../../redux/banks/bankSlice";
import { usePutBankActionMutation } from "../../../redux/banks/bankAPI";
import { useLazyGetActionsQuery } from "../../../redux/actions/actionsAPI";
// COMPONENTS
import DrawerCloseSaveButton from "../../components/drawer/DrawerCloseSaveButton";
import DrawerRowDivider from "../../components/drawer/DrawerRowDivider";
import GlobalAlert2 from "../../home/GlobalAlert2";
// INTERFACES
import { RootState } from "../../../app/store";
import { BankActionInterface } from "../../../interfaces/EdgeManagement.interface";
// AUTHORIZATION
import GETJwtToken from "../../../redux/authentication/authentication";

function BanksAssignActions() {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const dispatch = useDispatch();
	const [t] = useTranslation("global");
	const { Option } = Select;
	const BLANK_ACTION = {
		action_id: "",
		bank_id: "",
		button_type: "",
		timer: 1,
		toggle_action_id: "",
	};

	// ************************************************ */
	// USE STATE VARIABLES **************************** */
	const [action, setAction] = useState<BankActionInterface>(BLANK_ACTION);
	const [adapterActions, setAdapterActions] = useState<string[]>([]);
	const [actionsAlreadyAssigned, setActionsAlreadyAssigned] = useState<string[]>([]);

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { openDrawerAssignActions, bank } = useSelector((state: any) => state.bank);
	const { theme } = useSelector((state: RootState) => state.home);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */
	const [
		triggerPutAction,
		{
			isLoading: isLoadingPutAction,
			isSuccess: isSuccessPutAction,
			isError: isErrorPutAction,
			error: errorPutAction,
			reset: resetPutAction,
		},
	] = usePutBankActionMutation();

	const [triggerGetActions, { data: dataGetActions, isLoading: isLoadingGetActions, isError: isErrorGetActions }] =
		useLazyGetActionsQuery();

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const onClickClose = () => {
		setAction(BLANK_ACTION);
		setAdapterActions([]);
		setActionsAlreadyAssigned([]);
		dispatch(updateOpenDrawerAssignActions(false));
		resetPutAction();
	};

	const onChangeFormValue = (newValue: string, variableName: string) => {
		const copy: BankActionInterface = structuredClone(action);
		copy[variableName] = newValue;
		if (variableName === "button_type" && newValue === "TOGGLE") {
			copy.timer = 1;
		}
		setAction(copy);
	};

	const onClickSave = async () => {
		const token = await GETJwtToken();
		triggerPutAction({
			id: bank.id,
			token,
			body: {
				actions: [action],
				actions2delete: [],
			},
		});
	};

	const onClickDelete = async (id: string) => {
		const token = await GETJwtToken();
		triggerPutAction({
			id: bank.id,
			token,
			body: {
				actions: [],
				actions2delete: [id],
			},
		});
	};

	const checkFormIsValid = () => {
		let disableButton = true;
		if (
			action.action_id !== "" &&
			action.bank_id !== "" &&
			action.button_type !== "" &&
			action.toggle_action_id !== ""
		) {
			disableButton = false;
		}
		return disableButton;
	};

	const buildActionsInUse = () => {
		const newArr: any = [];
		bank.actions.map((item: any) => newArr.push(item.action_id));
		setActionsAlreadyAssigned(newArr);
	};

	const getInitialData = async () => {
		const token = await GETJwtToken();
		triggerGetActions({
			token,
		});
	};

	// ************************************************ */
	// USE EFFECT ************************************* */
	useEffect(() => {
		getInitialData();
	}, []);

	useEffect(() => {
		if (openDrawerAssignActions) {
			// Save ADAPTER actions
			if (bank && bank.adapter) {
				if (bank.adapter.action_catalogs) {
					setAdapterActions(bank.adapter.action_catalogs);
				} else {
					setAdapterActions([]);
				}
			} else if (dataGetActions && dataGetActions.data) {
				setAdapterActions(dataGetActions.data);
			}
			// Save bank ID
			const copy: BankActionInterface = structuredClone(action);
			copy.bank_id = bank.id;
			setAction(copy);
			// Save actions already in use
			if (bank && bank.actions) {
				buildActionsInUse();
			}
		}
	}, [openDrawerAssignActions]);

	useEffect(() => {
		onClickClose();
	}, [isSuccessPutAction, isErrorPutAction, isErrorGetActions]);

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<Spin spinning={isLoadingPutAction || isLoadingGetActions}>
			{/** ************************* */}
			{/** BODY */}
			<Drawer width='650' placement='right' onClose={onClickClose} closable={false} open={openDrawerAssignActions}>
				{/** ------------------------------------------------------------- */}
				{/** BANK INFORMATION */}
				<div>
					<Divider orientation='left' className='generalStyles__drawerDivider'>
						<h5>
							<FontAwesomeIcon icon={faPlugCircleCheck} className='generalStyles__info generalStyles__mrFix' />
							{t("edgeManagement.banks.bankInformation")}
						</h5>
					</Divider>
				</div>
				{/** ------------------------------------------------------------- */}
				{/** BANK  */}
				<div className={`drawer__box__${theme}`}>
					{/* BANK ID */}
					<div className='generalStyles__flex'>
						<div>
							<p className='drawer__title'>ID:</p>
						</div>
						<div className='drawer__textContainer'>
							<p className='drawer__text'>{bank && bank.id ? bank.id : "--"}</p>
						</div>
					</div>
					{/* BANK NAME */}
					<div className='generalStyles__flex'>
						<div>
							<p className='drawer__title'>{t("general.name")}</p>
						</div>
						<div className='drawer__textContainer'>
							<p className='drawer__text'>{bank && bank.name ? bank.name : "--"}</p>
						</div>
					</div>
					{/* TYPE */}
					<div className='generalStyles__flex'>
						<div>
							<p className='drawer__title'>{t("edgeManagement.lifts.type")}</p>
						</div>
						<div className='drawer__textContainer'>
							<p className='drawer__text'>
								{bank && bank.type === "ELEVATOR" && (
									<Tag color='blue' className='drawer__typeTag'>
										{bank.type}
									</Tag>
								)}
								{bank && bank.type === "ESCALATOR" && (
									<Tag color='volcano' className='drawer__typeTag'>
										{bank.type}
									</Tag>
								)}
								{bank && bank.type === "MOVINGWALK" && (
									<Tag color='green' className='drawer__typeTag'>
										{bank.type}
									</Tag>
								)}
							</p>
						</div>
					</div>
				</div>
				{/** ------------------------------------------------------------- */}
				{/** ACTIONS */}
				<br />
				<DrawerRowDivider icon={faGear} title={t("general.actions")} />
				<div className={`drawer__box__${theme}`}>
					{bank && bank.actions && bank.actions.length > 0 ? (
						<>
							{bank.actions.map((item: any) => (
								<div className='generalStyles__flex' key={item.action_id}>
									<div>
										<p style={{ paddingTop: "5px" }} className='drawer__title'>
											{item && item.action && item.action.label ? item.action.label : "--"}
										</p>
									</div>
									<div className='drawer__textContainer generalStyles__spaceBetween' style={{ width: "100%" }}>
										<div>
											<Tag color='green' className='drawer__typeTag'>
												{item.button_type}
											</Tag>
										</div>
										<div>
											<Tooltip title={t("general.delete")}>
												<FontAwesomeIcon
													icon={faTrash}
													onClick={() => {
														onClickDelete(item.action_id);
													}}
													className='generalStyles__warning generalStyles__mrFix'
													style={{
														fontSize: "22px",
														paddingLeft: "5px",
													}}
												/>
											</Tooltip>
										</div>
									</div>
								</div>
							))}
						</>
					) : (
						<div className='drawer__container'>
							<Empty description={t("edgeManagement.banks.actionsNoAssigned")} />
						</div>
					)}
				</div>
				{/** ------------------------------------------------------------- */}
				{/** BANK ASSIGN ACTIONS */}
				<br />
				<DrawerRowDivider icon={faGear} title={t("configuration.action.createNewAction")} />
				<div className={`drawer__box__${theme}`}>
					{adapterActions && adapterActions.length > 0 ? (
						<>
							{/** ACTION */}
							<div className='generalStyles__flex'>
								<div className='drawer__title'>
									<span className='generalStyles__inputFlexRequired'>*</span>
									<span className='drawer__text'>{t("general.action")}</span>
								</div>
								<div style={{ width: "370px" }}>
									<Select
										className='generalStyles__width100'
										placeholder={t("general.action")}
										onChange={(event: any) => {
											onChangeFormValue(event, "action_id");
										}}
										value={action.action_id === "" ? null : action.action_id}
										size='small'
										optionFilterProp='children'
										filterOption={(input: string, option: any) =>
											option.children.toLowerCase().includes(input.toLowerCase())
										}
									>
										{adapterActions.map((item: any) => (
											<Option key={item.id} value={item.id} disabled={actionsAlreadyAssigned.includes(item.id)}>
												{`${item.cmd || ""} / ${item.tooltip || ""}`}
											</Option>
										))}
									</Select>
								</div>
							</div>
							{/** TOGGLE ACTION */}
							<div className='generalStyles__flex mt-3'>
								<div className='drawer__title'>
									<span className='generalStyles__inputFlexRequired'>*</span>
									<span className='drawer__text'>Toggle {t("general.action")}</span>
								</div>
								<div style={{ width: "370px" }}>
									<Select
										className='generalStyles__width100'
										placeholder={`Toggle ${t("general.action")}`}
										onChange={(event: any) => {
											onChangeFormValue(event, "toggle_action_id");
										}}
										value={action.toggle_action_id === "" ? null : action.toggle_action_id}
										size='small'
										optionFilterProp='children'
										filterOption={(input: string, option: any) =>
											option.children.toLowerCase().includes(input.toLowerCase())
										}
									>
										{adapterActions.map((item: any) => (
											<Option key={item.id} value={item.id}>
												{item.label} / {item.tooltip}
											</Option>
										))}
									</Select>
								</div>
							</div>
							{/** BUTTON TYPE */}
							<div className='generalStyles__flex mt-3'>
								<div className='drawer__title'>
									<span className='generalStyles__inputFlexRequired'>*</span>
									<span className='drawer__text'>{t("general.buttonType")}</span>
								</div>
								<div style={{ width: "370px" }}>
									<Select
										className='generalStyles__width100'
										placeholder={t("general.buttonType")}
										size='small'
										onChange={(event: any) => {
											onChangeFormValue(event, "button_type");
										}}
										value={action.button_type === "" ? null : action.button_type}
										optionFilterProp='children'
										filterOption={(input: string, option: any) =>
											option.children.toLowerCase().includes(input.toLowerCase())
										}
									>
										{[
											{ id: "TOGGLE", name: "TOGGLE" },
											{ id: "HOLDBACK", name: "HOLDBACK" },
											{ id: "TURNBACK", name: "TURNBACK" },
										].map((item) => (
											<Option key={item.id} value={item.id}>
												{item.name}
											</Option>
										))}
									</Select>
								</div>
							</div>
							{/* TIMER */}
							{(action.button_type === "TURNBACK" || action.button_type === "HOLDBACK") && (
								<div className='generalStyles__flex mt-3'>
									<div className='drawer__title'>
										<span className='generalStyles__inputFlexRequired'>*</span>
										<span className='drawer__text'>{t("general.timer")}(s)</span>
									</div>
									<div style={{ width: "370px" }}>
										<InputNumber
											size='small'
											style={{ width: "100%" }}
											placeholder='Timer'
											value={action.timer}
											onChange={(event: any) => {
												onChangeFormValue(event, "timer");
											}}
											min={1}
											max={999}
										/>
									</div>
								</div>
							)}
						</>
					) : (
						<Empty
							description={`${t("adapter.theAdapter")} ${
								bank && bank.adapter && bank.adapter.name ? bank.adapter.name : ""
							} ${t("adapter.hasNoActions")}.`}
						/>
					)}
				</div>
				<br />
				{/** ---------------------------------------------------- */}
				{/** COMPONENT CLOSE SAVE BUTTON */}
				{adapterActions && adapterActions.length > 0 ? (
					<DrawerCloseSaveButton
						onClickClose={onClickClose}
						onClickSave={onClickSave}
						disableSaveButton={checkFormIsValid()}
					/>
				) : (
					<Button onClick={onClickClose} className='buttonStyle__16'>
						{t("general.close")}
					</Button>
				)}
			</Drawer>
			{/** ---------------------------------------------------- */}
			{/** GLOBAL ALERT */}
			<GlobalAlert2
				isError={isErrorPutAction}
				isSuccess={isSuccessPutAction}
				requestType='PUT'
				error={errorPutAction}
				name='Bank'
			/>
		</Spin>
	);
}

export default BanksAssignActions;
